import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";

const impactScores = { low: 1, medium: 2, high: 3, null: 1 };

const LineGraph = ({ data, months }) => {
  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    // Process each data point to calculate RPS and store related findings/improvements
    const combinedDataPoints = data.map((item, index) => {
      const hasFindings =
        item.relatedFindings && item.relatedFindings.length > 0;
      const hasImprovements =
        item.relatedImprovements && item.relatedImprovements.length > 0;

      // Calculate impact of findings
      const findingImpact = item.relatedFindings.reduce((total, finding) => {
        return total + impactScores[finding.risk.level];
      }, 0);

      // Calculate impact of improvements (subtract based on risk level)
      const improvementImpact = item.relatedImprovements.reduce(
        (total, improvement) => {
          improvement.linked_findings.forEach((findingKey) => {
            const linkedFinding = item.relatedFindings.find(
              (f) => f.key === findingKey,
            );
            if (linkedFinding) {
              const impact = impactScores[linkedFinding.risk.level];
              total -= impact; // Subtract the impact based on the linked finding's risk level
            }
          });
          return total;
        },
        0,
      );

      const RPS = findingImpact + improvementImpact;

      // Set the point color
      let pointColor = "#EBFF99"; // Default color
      if (hasFindings && !hasImprovements) {
        pointColor = "#AD88C6";
      } else if (hasImprovements && !hasFindings) {
        pointColor = "#88AB8E";
      } else if (hasFindings && hasImprovements) {
        pointColor = "#88AB8E";
      }

      return {
        x: months[index],
        y: RPS,
        findings: item.relatedFindings,
        improvements: item.relatedImprovements,
        findingImpact,
        improvementImpact,
        RPS,
        pointBackgroundColor: pointColor,
        pointBorderColor: "#112C22",
      };
    });

    const chartData = {
      labels: months,
      datasets: [
        {
          label: "RPS",
          data: combinedDataPoints,
          fill: true,
          borderColor: "#007940",
          tension: 0.1,
          pointBackgroundColor: combinedDataPoints.map(
            (point) => point.pointBackgroundColor,
          ),
          pointBorderColor: combinedDataPoints.map(
            (point) => point.pointBorderColor,
          ),
          backgroundColor: "#EDE6DE",
          pointRadius: 5, // Show points on the line
        },
      ],
    };

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      plugins: {
        legend: {
          display: true,
          labels: {
            generateLabels: (chart) => [
              {
                text: "RPS",
                fillStyle: "#EBFF99", // Yellow color for RPS
                strokeStyle: "#112C22",
                lineWidth: 1,
              },
              {
                text: "Findings",
                fillStyle: "#AD88C6", // Purple color for findings
                strokeStyle: "#112C22",
                lineWidth: 1,
              },
              {
                text: "Improvements",
                fillStyle: "#88AB8E", // Green color for improvements
                strokeStyle: "#112C22",
                lineWidth: 1,
              },
            ],
          },
        },
        tooltip: {
          enabled: false, // Disable default tooltip
          external: function (context) {
            // Tooltip Element
            let tooltipEl = document.getElementById("chartjs-tooltip");

            // Create tooltip element if it doesn't exist
            if (!tooltipEl) {
              tooltipEl = document.createElement("div");
              tooltipEl.id = "chartjs-tooltip";
              tooltipEl.style.position = "absolute";
              tooltipEl.style.pointerEvents = "none";
              tooltipEl.style.opacity = 0;
              tooltipEl.style.transition = "opacity 0.3s ease";
              document.body.appendChild(tooltipEl);
            }

            const tooltipModel = context.tooltip;

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set text for the tooltip
            const dataPoint = context.tooltip.dataPoints[0].raw;
            let tooltipContent = `<div><strong>RPS: ${dataPoint.RPS}</strong></div>`;

            // Add RPS Calculation Breakdown
            tooltipContent += `<div><strong>RPS Calculation:</strong></div>`;
            tooltipContent += `<div>Findings Impact: +${dataPoint.findingImpact}</div>`;
            tooltipContent += `<div>Improvements Impact: ${dataPoint.improvementImpact}</div>`;

            // Add findings details if this point has findings
            if (dataPoint.findings && dataPoint.findings.length > 0) {
              tooltipContent += `<div style="color: #AD88C6; font-weight: bold">Findings:</div>`;
              dataPoint.findings.forEach((finding) => {
                const impact = impactScores[finding.risk.level];

                // Add colored text for the risk levels
                let levelColor = "#007940"; // Default color for low
                if (finding.risk.level === "medium") {
                  levelColor = "#FF7427"; // Orange for medium
                } else if (finding.risk.level === "high") {
                  levelColor = "#C60C30"; // Red for high
                }

                tooltipContent += `<div>${finding.key}: +${impact} <span style="color:${levelColor};">(${finding.risk.level} level)</span></div>`;
              });
            }

            // Add improvements details if this point has improvements
            if (dataPoint.improvements && dataPoint.improvements.length > 0) {
              tooltipContent += `<div style="color: #88AB8E; font-weight: bold">Improvements:</div>`;
              dataPoint.improvements.forEach((improvement) => {
                improvement.linked_findings.forEach((linkedFindingKey) => {
                  const linkedFinding = dataPoint.findings.find(
                    (f) => f.key === linkedFindingKey,
                  );
                  if (linkedFinding) {
                    const impact = impactScores[linkedFinding.risk.level];

                    // Add colored text for the linked finding's risk level
                    let levelColor = "#007940";
                    if (linkedFinding.risk.level === "medium") {
                      levelColor = "#FF7427";
                    } else if (linkedFinding.risk.level === "high") {
                      levelColor = "#C60C30";
                    }

                    tooltipContent += `<div>${improvement.key}: -${impact} <span style="color:${levelColor};">(${linkedFinding.risk.level} level)</span></div>`;
                  }
                });
              });
            }

            // Set the inner HTML of the tooltip
            tooltipEl.innerHTML = tooltipContent;

            // Set position and styles of tooltip
            const position = context.chart.canvas.getBoundingClientRect();
            tooltipEl.style.opacity = 1;
            tooltipEl.style.left =
              position.left + window.pageXOffset + tooltipModel.caretX + "px";
            tooltipEl.style.top =
              position.top + window.pageYOffset + tooltipModel.caretY + "px";

            // Apply inline styles to control appearance
            tooltipEl.style.backgroundColor = "white";
            tooltipEl.style.border = "1px solid #EAECF0";
            tooltipEl.style.borderRadius = "8px";
            tooltipEl.style.boxShadow = " 0 6px 12px 4px rgba(0, 0, 0, 0.12)";
            tooltipEl.style.padding = "10px";
            tooltipEl.style.height = "auto";
            tooltipEl.style.overflowY = "auto";
            tooltipEl.style.whiteSpace = "normal";
          },
        },
      },
    };

    const ctx = canvasRef.current.getContext("2d");
    chartRef.current = new Chart(ctx, {
      type: "line",
      data: chartData,
      options: chartOptions,
    });

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [data, months]);

  return (
    <div style={{ height: "200px", width: "100%" }}>
      <canvas
        ref={canvasRef}
        style={{ height: "100%", width: "100%" }}
      ></canvas>
    </div>
  );
};

export default LineGraph;
