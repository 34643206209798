import React, { useEffect, useState } from "react";
import Modal from "../components/Modal/Modal";
import styles from "./ImprovementProjectsModal.module.scss";
import classNames from "classnames";
import Loader from "../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "../redux/modalStore";
import { setImprovementsIssueData } from "../redux/improvementsStore";
import Table from "../components/Table/Table";
import SecondaryButton from "../components/Button/SecondaryButton";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button/Button";
import { Field, Form, Formik } from "formik";
import EditIcon from "../icons/edit.svg";
import Select from "../components/Select/Select";
import Pill from "../components/Pill/Pill";
import Textarea from "../components/Textarea/Textarea";
import { getOrganizationData } from "../actions/organization";
import axiosInstance from "../utils/utils";
import * as Yup from "yup";
import Input from "../components/Input/Input";
import ModalSideBar from "../components/ModalSideBar/ModalSideBar";
import ConfirmationPopup from "./ConfirmationPopup";

const ImprovementsProjectsModal = ({ improvementProjectsData, isLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const sessionToken = localStorage.getItem("session_token");

  const { linkedData } = useSelector((state) => state.organization);

  const [editField, setEditField] = useState(null);
  const [editableData, setEditableData] = useState(
    improvementProjectsData || {},
  );
  const [unsavedChanges, setUnsavedChanges] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [updates, setUpdates] = useState(
    improvementProjectsData?.updates || [],
  );
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  useEffect(() => {
    setEditableData(improvementProjectsData || {});
    setUnsavedChanges({});
    setEditField(null);
    setLoading(false);
    setUpdates(improvementProjectsData?.updates || []);
  }, [improvementProjectsData]);

  const handleOnClose = (dirty, resetForm) => {
    if (dirty) {
      setShowConfirmationPopup(true);
    } else {
      dispatch(hideModal());
      if (resetForm && typeof resetForm === "function") {
        resetForm();
      }
      setEditableData(improvementProjectsData || {});
      setEditField(null);
      setSidebarOpen(false);
    }
  };

  const handleConfirmClose = (resetForm) => {
    setShowConfirmationPopup(false);
    dispatch(hideModal());
    setEditField(null);
    resetForm();
  };

  const handleCancel = () => {
    setShowConfirmationPopup(false);
  };

  const handleFieldChange = (field, value) => {
    setUnsavedChanges((prevChanges) => ({
      ...prevChanges,
      [field]: value,
    }));
    setEditableData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSave = async (values, resetForm) => {
    // Define a helper function to sanitize the data and handle undefined, null, or empty values
    const sanitizeData = (data) => {
      return Object.keys(data).reduce((acc, key) => {
        if (Array.isArray(data[key])) {
          // If the array is empty or contains only empty strings, send it as an empty array
          acc[key] =
            data[key].filter((item) =>
              typeof item === "string" ? item.trim() !== "" : item,
            ) || [];
        } else if (typeof data[key] === "string") {
          // Allow empty strings to be sent
          acc[key] = data[key].trim();
        } else {
          // For other types, retain as is or set to null if explicitly undefined
          acc[key] = data[key] ?? "";
        }
        return acc;
      }, {});
    };

    // Sanitize both the form values and the original improvementProjectsData for comparison
    const sanitizedValues = sanitizeData(values);
    const sanitizedOriginalData = sanitizeData(improvementProjectsData);

    // Detect changed fields
    const changedFields = {};
    for (const key in sanitizedValues) {
      if (
        Array.isArray(sanitizedValues[key]) ||
        typeof sanitizedValues[key] === "object"
      ) {
        // Compare arrays or objects by their JSON string representations
        if (
          JSON.stringify(sanitizedValues[key]) !==
          JSON.stringify(sanitizedOriginalData[key])
        ) {
          changedFields[key] = sanitizedValues[key];
        }
      } else {
        // Compare primitive values directly
        if (sanitizedValues[key] !== sanitizedOriginalData[key]) {
          changedFields[key] = sanitizedValues[key];
        }
      }
    }

    // If no changes detected, exit early
    if (Object.keys(changedFields).length === 0) {
      return; // No changes to save
    }

    const payload = {
      id: improvementProjectsData?.id, // Use the existing improvement project ID
      ...changedFields, // Include only changed fields
    };

    setLoading(true); // Set loading state to true

    try {
      // Make the PATCH request to update the improvement project data
      const response = await axiosInstance.patch(
        `/nocodb/improvements?org_id=${orgId}`,
        [payload], // Send an array with the payload
        {
          headers: {
            "Content-Type": "application/json",
            "session-token": sessionToken, // Include the session token for authentication
          },
        },
      );

      // Handle successful response, update local state with the saved changes
      setEditableData((prevData) => ({
        ...prevData,
        ...changedFields,
      }));

      setUnsavedChanges({});
      setEditField(null);

      // Refresh the organization data to reflect the updated improvements
      await dispatch(
        getOrganizationData(
          orgId,
          sessionToken,
          navigate,
          false,
          false,
          true,
          false,
          false,
          false,
        ),
      );
      setTimeout(() => {
        resetForm();
      }, 0);
      handleOnClose(); // Close the modal after saving and fetching data
    } catch (error) {
      console.error("Error saving improvements data:", error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const statusOptions = [
    { label: "Open", value: "Open" },
    { label: "In Progress", value: "In Progress" },
    { label: "In Review", value: "In Review" },
    { label: "Done", value: "Done" },
  ];

  const truncateText = (text, maxLength) => {
    if (!text) {
      // If text is null, undefined, or an empty string
      return "There is no description provided";
    }

    if (text.length <= maxLength) {
      return text;
    }

    return (
      <>
        {text.substring(0, maxLength)}
        <span className={styles.readMore}>... read more</span>
      </>
    );
  };

  const handleNavigationClick = (path) => {
    navigate(`/${orgId}/${path}`);
    handleOnClose();
  };

  const safeLinkedData = Array.isArray(linkedData) ? linkedData : [];

  const columns = [
    {
      accessorKey: "key",
      header: "Key",
      size: 100,
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 350,
      cell: (props) => <div>{truncateText(props.getValue(), 80)}</div>,
    },
    {
      accessorKey: "responsible",
      header: "Responsible",
      size: 200,
      cell: (props) => {
        return props.row.original.responsible ? (
          <div>{props.row.original.responsible}</div>
        ) : (
          <div>Not defined</div>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 140,
      cell: (props) => {
        const status = props.getValue();
        let statusClassName = "";

        switch (status) {
          case "Open":
            statusClassName = styles.openStatus;
            break;
          case "In Progress":
            statusClassName = styles.inProgressStatus;
            break;
          case "In Review":
            statusClassName = styles.inReviewStatus;
            break;
          case "Done":
            statusClassName = styles.resolvedStatus;
            break;
          default:
            break;
        }

        return (
          <div className={classNames(styles.statusCell, statusClassName)}>
            {status}
          </div>
        );
      },
    },
  ];

  const renderEmails = (emails, editField, fieldName, setEditField) => {
    if (editField === fieldName) {
      return (
        <Field
          name={fieldName}
          component={Pill}
          placeholder="Enter emails"
          limit={5}
        />
      );
    }

    if (Array.isArray(emails)) {
      return emails.map((email) => (
        <div key={email} className={styles.items}>
          {email}
        </div>
      ));
    } else if (typeof emails === "string") {
      return <div className={styles.items}>{emails}</div>;
    }

    return null;
  };

  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  const validationSchema = Yup.object().shape({
    responsible: Yup.string().matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email address",
    ),
  });

  return (
    <>
      <Formik
        initialValues={editableData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => handleSave(values, resetForm)}
      >
        {({ values, dirty, resetForm, setFieldValue }) => (
          <>
            <Modal
              name="improvement_projects_modal"
              title={improvementProjectsData?.key}
              width="fit-content"
              minWidth="50vw"
              onClose={() => handleOnClose(dirty, resetForm)}
            >
              <div className={styles.content}>
                <Form className={styles.formikContent}>
                  <div
                    className={classNames(styles.firstRow, styles.spaceBetween)}
                  >
                    <div className={classNames(styles.row, styles.start)}>
                      <div className={styles.subheading}>Name:</div>
                      <div>{improvementProjectsData?.name}</div>
                    </div>
                    <Button
                      text="See Updates"
                      onClick={toggleSidebar}
                      className={styles.toggleSidebarButton}
                      type="Button"
                    />
                  </div>
                  <div className={styles.row}>
                    <div className={styles.title}>Status:</div>
                    <div className={styles.statusContainer}>
                      {editField === "status" ? (
                        <Field
                          name="status"
                          component={Select}
                          options={statusOptions}
                          value={values.status}
                          onChange={(e) => {
                            setFieldValue("status", e.target.value);
                            handleFieldChange("status", e.target.value);
                            setEditField(null);
                          }}
                        />
                      ) : (
                        <>
                          <div
                            className={classNames(
                              styles.statusCell,
                              values.status === "In Progress"
                                ? styles.inProgressStatus
                                : values.status === "Closed"
                                  ? styles.closedStatus
                                  : values.status === "Done"
                                    ? styles.resolvedStatus
                                    : styles.openStatus,
                            )}
                          >
                            {values.status}
                          </div>
                          <img
                            src={EditIcon}
                            className={styles.editIcon}
                            onClick={() => setEditField("status")}
                            alt="Edit"
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {/* Responsible */}
                  <div className={styles.row}>
                    <div className={styles.title}>Responsible:</div>
                    <div className={styles.bold}>
                      {editField === "responsible" ? (
                        <Field
                          name="responsible"
                          component={Input}
                          placeholder="Enter responsible email"
                        />
                      ) : (
                        <div>
                          {Array.isArray(values.responsible)
                            ? values.responsible.join(", ")
                            : values.responsible}
                        </div>
                      )}
                    </div>
                    {editField !== "responsible" && (
                      <img
                        src={EditIcon}
                        className={styles.editIcon}
                        onClick={() => setEditField("responsible")}
                        alt="Edit"
                      />
                    )}
                  </div>

                  {/* Accountable */}
                  <div className={styles.row}>
                    <div className={styles.title}>Accountable:</div>
                    <div className={styles.bold}>
                      {renderEmails(
                        values.accountable || editableData.accountable,
                        editField,
                        "accountable",
                        setEditField,
                      )}
                    </div>
                    <img
                      src={EditIcon}
                      className={styles.editIcon}
                      onClick={() => setEditField("accountable")}
                      alt="Edit"
                    />
                  </div>

                  {/* Consulted */}
                  <div className={styles.row}>
                    <div className={styles.title}>Consulted:</div>
                    <div className={styles.bold}>
                      {renderEmails(
                        values.consulted || editableData.consulted,
                        editField,
                        "consulted",
                        setEditField,
                      )}
                    </div>
                    <img
                      src={EditIcon}
                      className={styles.editIcon}
                      onClick={() => setEditField("consulted")}
                      alt="Edit"
                    />
                  </div>

                  {/* Informed */}
                  <div className={styles.row}>
                    <div className={styles.title}>Informed:</div>
                    <div className={styles.bold}>
                      {renderEmails(
                        values.informed || editableData.informed,
                        editField,
                        "informed",
                        setEditField,
                      )}
                    </div>
                    <img
                      src={EditIcon}
                      className={styles.editIcon}
                      onClick={() => setEditField("informed")}
                      alt="Edit"
                    />
                  </div>

                  {/* Description Field */}
                  <div className={styles.subheading}>Description:</div>
                  {editField === "description" ? (
                    <Field name="description" component={Textarea} />
                  ) : (
                    <div className={styles.description}>
                      {values?.description ||
                        improvementProjectsData?.description}
                      <img
                        src={EditIcon}
                        className={styles.editIcon}
                        onClick={() => setEditField("description")}
                        alt="Edit"
                      />
                    </div>
                  )}

                  {linkedData?.linked_findings?.length > 0 && (
                    <>
                      <div className={styles.title}>Linked Findings</div>
                      <div className={styles.tableContainer}>
                        <Table
                          columns={columns}
                          data={linkedData?.linked_findings}
                        />
                      </div>
                      <SecondaryButton
                        text="View all findings..."
                        className={styles.secondaryButton}
                        onClick={() => handleNavigationClick("findings")}
                      />
                    </>
                  )}

                  {editField && (
                    <Button
                      text={loading ? "Saving..." : "Save Changes"}
                      type="submit"
                      className={styles.submitButton}
                      disabled={loading}
                    />
                  )}
                </Form>
                <ModalSideBar
                  isOpen={isSidebarOpen}
                  onClose={() => setSidebarOpen(false)}
                  updates={updates}
                  setUpdates={setUpdates}
                  improvementId={improvementProjectsData?.id}
                />
              </div>
            </Modal>
            {showConfirmationPopup && (
              <ConfirmationPopup
                onConfirmClose={() => handleConfirmClose(resetForm)}
                onCancel={handleCancel}
              />
            )}
          </>
        )}
      </Formik>
    </>
  );
};

export default ImprovementsProjectsModal;
