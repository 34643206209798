import React, { useState, useEffect } from "react";
import {
  getColor,
  generateCumulativeTimeSeries,
  getPast12Months,
} from "../../utils/helpers";
import styles from "./GraphAccordion.module.scss";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import LineGraph from "../LineGraph/LineGraph";
import DownArrowIcon from "../../icons/down-arrow.svg";
import UpArrowIcon from "../../icons/up-arrow.svg";

const GraphAccordion = ({ findingsData, improvementsData }) => {
  const [mainChaptersData, setMainChaptersData] = useState({
    chapters: {},
    globalMin: Infinity,
    globalMax: -Infinity,
  });
  const [openGroup, setOpenGroup] = useState(null);

  console.log(findingsData, "findings");
  console.log(improvementsData, "improvements");

  const { risksData } = useSelector((state) => state.organization);

  useEffect(() => {
    const groupedData = generateCumulativeTimeSeries(
      findingsData,
      improvementsData,
    );

    console.log(groupedData, "grouped data");

    // Structure to group sub-chapters under their main chapters
    const chapters = {};
    let globalMin = Infinity;
    let globalMax = -Infinity;

    Object.keys(groupedData).forEach((subChapter) => {
      const mainChapter = subChapter.split("-")[0]; // Extract main chapter
      if (!chapters[mainChapter]) {
        chapters[mainChapter] = {
          subChapters: [],
          cumulativeData: groupedData[subChapter].map((monthData) => ({
            ...monthData,
            count: 0,
          })),
        };
      }
      chapters[mainChapter].subChapters.push({
        key: subChapter,
        data: groupedData[subChapter],
      });

      // Add up counts for the main chapter legend
      groupedData[subChapter].forEach((monthData, index) => {
        const count = monthData.count;
        chapters[mainChapter].cumulativeData[index].count += count;

        // Update global min and max
        if (count < globalMin) globalMin = count;
        if (count > globalMax) globalMax = count;
      });
    });

    setMainChaptersData({ chapters, globalMin, globalMax });
  }, [findingsData, improvementsData]);

  // Get past 12 months for headers
  const months = getPast12Months();

  const toggleAccordion = (mainChapterKey) => {
    setOpenGroup(openGroup === mainChapterKey ? null : mainChapterKey);
  };

  return (
    <div className={styles.graphAccordion}>
      {Object.keys(mainChaptersData.chapters).map((mainChapterKey) => {
        const { subChapters, cumulativeData } =
          mainChaptersData.chapters[mainChapterKey];

        // Finding the corresponding qms_description for the main chapter
        const matchingRisk = Object.values(risksData).find(
          (risk) => risk.qms_id === mainChapterKey,
        );
        const riskDescription = matchingRisk
          ? matchingRisk.qms_description
          : "";

        const min = mainChaptersData.globalMin;
        const max = mainChaptersData.globalMax;

        return (
          <div key={mainChapterKey} className={styles.accordionItem}>
            <div
              className={styles.accordionHeader}
              onClick={() => toggleAccordion(mainChapterKey)}
            >
              <div className={styles.legend}>
                {cumulativeData.map((item, index) => (
                  <div
                    key={index}
                    className={styles.monthSquare}
                    data-tooltip-id="month-tooltip"
                    data-tooltip-html={`<strong>Month:</strong> ${months[index]} | <strong>RPS:</strong> ${item.count}`}
                    style={{
                      backgroundColor: getColor(item.count, min, max),
                    }}
                  ></div>
                ))}
                <div className={styles.name}>
                  {mainChapterKey} {riskDescription && ` - ${riskDescription}`}
                </div>
                {openGroup === mainChapterKey ? (
                  <img
                    src={UpArrowIcon}
                    alt="Up Arrow Icon"
                    className={styles.icon}
                  />
                ) : (
                  <img
                    src={DownArrowIcon}
                    alt="Down Arrow Icon"
                    className={styles.icon}
                  />
                )}
              </div>
            </div>
            {openGroup === mainChapterKey && (
              <div className={styles.accordionBody}>
                {subChapters.map(
                  ({ key: subChapterKey, data: subChapterData }) => {
                    const minSub = Math.min(
                      ...subChapterData.map((item) => item.count),
                    );
                    const maxSub = Math.max(
                      ...subChapterData.map((item) => item.count),
                    );

                    return (
                      <div key={subChapterKey} className={styles.graph}>
                        <div className={styles.subChapterTitle}>
                          {subChapterKey}
                        </div>
                        <LineGraph
                          data={subChapterData.map((item, index) => {
                            console.log(item, "item u mapu");
                            return {
                              month: months[index],
                              count: item.count,
                              relatedFindings: item.relatedFindings || [], // Add related findings
                              relatedImprovements:
                                item.relatedImprovements || [], // Add related improvements
                            };
                          })}
                          months={months}
                        />
                        <div className={styles.legend}>
                          {subChapterData.map((item, index) => (
                            <div
                              key={index}
                              className={styles.monthSquare}
                              data-tooltip-id="month-tooltip"
                              data-tooltip-content={`Month: ${months[index]} | Count: ${item.count}`}
                              style={{
                                backgroundColor: getColor(
                                  item.count,
                                  minSub,
                                  maxSub,
                                ),
                              }}
                            ></div>
                          ))}
                        </div>
                      </div>
                    );
                  },
                )}
              </div>
            )}
          </div>
        );
      })}
      <Tooltip id="month-tooltip" place="top" className={styles.tooltip} />
    </div>
  );
};

export default GraphAccordion;
