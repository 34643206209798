import React, { useState, useEffect, useRef } from "react";
import styles from "./CustomTable.module.scss";
import { CUSTOM_MODULE_LOADER } from "../../constants/loader";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import classNames from "classnames";
import Separator from "../Separator/Separator";
import SecondaryButton from "../Button/SecondaryButton";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import TableIcon from "../../icons/table.svg";
import QMSAccordion from "../QMSAccordion/QMSAccordion";

const CustomTable = ({ selectedChapter, className }) => {
  const navigate = useNavigate();

  const loadingContexts = useSelector((state) => state.loader.loadingContexts);
  const { data, permits } = useSelector((state) => state.organization);

  const riskAnalyseData = data?.risks;

  const [activeHeader, setActiveHeader] = useState("define");
  const [isTableExpanded, setIsTableExpanded] = useState(false);

  const orgId = localStorage.getItem("orgId");

  const isModuleLoading = loadingContexts[CUSTOM_MODULE_LOADER];

  const toggleTable = () => {
    setIsTableExpanded(!isTableExpanded);
  };

  console.log(selectedChapter, "selected chapter");

  const getStatusClass = (status) => {
    switch (status) {
      case "Open":
        return styles.statusOpen;
      case "In Progress":
        return styles.statusInProgress;
      case "Done":
        return styles.statusDone;
      case "Closed":
        return styles.statusClosed;
      default:
        return "";
    }
  };

  const renderDefineContent = () => {
    // Filter all permits to check if there are any questions matching the selected chapter
    const filteredPermits = Object.values(permits).filter((permit) => {
      const filteredQuestions = permit.questions?.filter((question) =>
        question.chapter?.startsWith(selectedChapter),
      );
      return filteredQuestions && filteredQuestions.length > 0;
    });

    // If no filtered permits, return "Sorry, there is no data"
    if (filteredPermits.length === 0) {
      return <div className={styles.noData}>Sorry, there is no data</div>;
    }

    // Otherwise, render the filtered permits
    return (
      <div className={styles.permitWrapper}>
        {filteredPermits.map((permit) => {
          // Filter questions where chapter starts with the selectedChapter and sort by question.num
          const filteredQuestions = permit.questions
            ?.filter((question) =>
              question.chapter?.startsWith(selectedChapter),
            )
            ?.sort((a, b) => a.num - b.num); // Sort questions by num in ascending order

          return (
            <QMSAccordion
              key={permit.identifier}
              permit={{ ...permit, questions: filteredQuestions }} // Pass only filtered and sorted questions
            />
          );
        })}
      </div>
    );
  };

  const renderMeasureContent = () => {
    if (!selectedChapter) {
      return <div className={styles.noData}>Sorry, there is no data</div>;
    }

    // Filter the audits based on whether the qms_id starts with the selectedChapter
    const filteredAudits = Object.values(data.audit_tasks).filter((audit) =>
      audit.form.some((formItem) =>
        formItem.risk.qms_id.startsWith(selectedChapter),
      ),
    );

    // Filter the findings based on whether the qms_id starts with the selectedChapter
    const filteredFindings = Object?.values(data.findings)?.filter((finding) =>
      finding?.risk?.qms_id?.startsWith(selectedChapter),
    );

    return filteredAudits.length > 0 || filteredFindings.length > 0 ? (
      <div className={styles.measureWrapper}>
        <div className={styles.findingsWrapper}>
          {filteredFindings.map((finding) => (
            <div key={finding.key} className={styles.finding}>
              <span
                className={classNames(
                  styles.key,
                  getStatusClass(finding.status),
                )}
              >
                {finding.key}
              </span>
              <span>{finding.description}</span>
            </div>
          ))}
          {filteredFindings.length > 0 && (
            <SecondaryButton
              text="View all findings..."
              className={styles.button}
              onClick={() => navigate(`/${orgId}/findings`)}
            />
          )}
        </div>
        <Separator />
        <div className={styles.auditsWrapper}>
          {filteredAudits.map((audit) => (
            <div key={audit.key} className={styles.audit}>
              <span
                className={classNames(styles.key, getStatusClass(audit.status))}
              >
                {audit.key}
              </span>
              <span>{audit.name}</span>
            </div>
          ))}
          {filteredAudits.length > 0 && (
            <SecondaryButton
              text="View all audits..."
              className={styles.button}
              onClick={() => navigate(`/${orgId}/audits`)}
            />
          )}
        </div>
      </div>
    ) : (
      <div className={styles.noData}>Sorry, there is no data</div>
    );
  };

  const renderAnalyseContent = () => {
    if (!selectedChapter) {
      return <div className={styles.noData}>Sorry, there is no data</div>;
    }

    // Convert riskAnalyseData to an array to filter it
    const riskEntries = Object.values(riskAnalyseData);

    // Filter risks based on selectedChapter
    const filteredRisks = riskEntries.filter((risk) =>
      risk.qms_id.startsWith(selectedChapter),
    );

    // Sort the filtered risks by qms_id using natural sorting
    const sortedRisks = filteredRisks.sort((a, b) => {
      const regex = /(\D+)(\d+)(.*)/;
      const [, aPrefix, aNumber] = a.qms_id.match(regex);
      const [, bPrefix, bNumber] = b.qms_id.match(regex);

      if (aPrefix === bPrefix) {
        return parseInt(aNumber, 10) - parseInt(bNumber, 10);
      }

      return a.qms_id.localeCompare(b.qms_id, undefined, { numeric: true });
    });

    // Filter by risk level
    const filteredHighRisks = sortedRisks.filter(
      (risk) => risk.level.toLowerCase() === "high",
    );
    const filteredMediumRisks = sortedRisks.filter(
      (risk) => risk.level.toLowerCase() === "medium",
    );
    const filteredLowRisks = sortedRisks.filter(
      (risk) => risk.level.toLowerCase() === "low",
    );

    return filteredHighRisks.length ||
      filteredMediumRisks.length ||
      filteredLowRisks.length ? (
      <div>
        {filteredHighRisks.length > 0 && (
          <div className={styles.high}>
            <span className={styles.title}>High risk:</span>
            {filteredHighRisks.map((analyse) => {
              let riskNumber = analyse.risk_id.toString();

              return (
                <div key={analyse.risk_id} className={styles.riskWrapper}>
                  <div className={classNames(styles.badge, styles.highBadge)}>
                    {riskNumber}
                  </div>
                  <div>{analyse.description}</div>
                </div>
              );
            })}
          </div>
        )}
        {filteredMediumRisks.length > 0 && (
          <div className={styles.medium}>
            <span className={styles.title}>Medium risk:</span>
            {filteredMediumRisks.map((analyse) => {
              let riskNumber = analyse.risk_id.toString();

              return (
                <div key={analyse.risk_id} className={styles.riskWrapper}>
                  <div className={classNames(styles.badge, styles.mediumBadge)}>
                    {riskNumber}
                  </div>
                  <div>{analyse.description}</div>
                </div>
              );
            })}
          </div>
        )}
        {filteredLowRisks.length > 0 && (
          <div className={styles.low}>
            <span className={styles.title}>Low risk:</span>
            {filteredLowRisks.map((analyse) => {
              let riskNumber = analyse.risk_id.toString();

              return (
                <div key={analyse.risk_id} className={styles.riskWrapper}>
                  <div className={classNames(styles.badge, styles.lowBadge)}>
                    {riskNumber}
                  </div>
                  <div>{analyse.description}</div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    ) : (
      <div className={styles.noData}>Sorry, there is no data</div>
    );
  };

  const renderImproveContent = () => {
    if (!selectedChapter) {
      return <div className={styles.noData}>Sorry, there is no data</div>;
    }

    // Convert data.findings to an array
    const findingsArray = Object.values(data.findings);

    // Filter improvements based on whether their linked_findings have a qms_id that starts with the selectedChapter
    const filteredImprovements = Object.values(data.improvements).filter(
      (improvement) =>
        improvement.linked_findings.some((findingKey) => {
          const linkedFinding = findingsArray.find((f) => f.key === findingKey);
          return (
            linkedFinding &&
            linkedFinding.risk.qms_id.startsWith(selectedChapter)
          );
        }),
    );

    return filteredImprovements.length > 0 ? (
      <div className={styles.improvementsWrapper}>
        {filteredImprovements.map((improvement) => (
          <div key={improvement.key} className={styles.improvement}>
            <span
              className={classNames(
                styles.key,
                getStatusClass(improvement.status),
              )}
            >
              {improvement.key}
            </span>
            <span>{improvement.description}</span>
          </div>
        ))}
        <SecondaryButton
          text="View all improvements..."
          className={styles.button}
          onClick={() => navigate(`/${orgId}/improvement-projects`)}
        />
      </div>
    ) : (
      <div className={styles.noData}>Sorry, there is no data</div>
    );
  };

  const renderContent = () => {
    switch (activeHeader) {
      case "define":
        return renderDefineContent();
      case "measure":
        return renderMeasureContent();
      case "analyse":
        return renderAnalyseContent();
      case "improve":
        return renderImproveContent();
      default:
        return null;
    }
  };

  const getActiveHeaderIndex = () => {
    const headers = ["define", "measure", "analyse", "improve"];
    return headers.indexOf(activeHeader);
  };

  // Disable tab switching if the table is collapsed
  const handleTabClick = (tab) => {
    if (isTableExpanded) {
      setActiveHeader(tab);
    }
  };

  return (
    selectedChapter && (
      <div
        className={classNames(
          styles.wrapper,
          className,
          isTableExpanded && styles.expanded,
        )}
      >
        <SecondaryButton
          className={styles.toggleButton}
          onClick={toggleTable}
          text={isTableExpanded ? "Collapse Data" : "Expand Data"}
          icon={isTableExpanded ? null : TableIcon}
        />
        <div className={styles.header}>
          <div
            className={classNames(
              styles.indicator,
              activeHeader,
              activeHeader === "define" && styles.define,
              activeHeader === "measure" && styles.measure,
              activeHeader === "analyse" && styles.analyse,
              activeHeader === "improve" && styles.improve,
              !isTableExpanded && styles.disabledIndicator,
            )}
            style={{ left: `${getActiveHeaderIndex() * 25}%` }}
          />
          <div
            onClick={() => handleTabClick("define")}
            className={classNames(
              styles.th,
              activeHeader === "define" && styles.active,
              !isTableExpanded && styles.disabledTab,
            )}
          >
            Define
          </div>
          <div
            onClick={() => handleTabClick("measure")}
            className={classNames(
              styles.th,
              activeHeader === "measure" && styles.active,
              !isTableExpanded && styles.disabledTab,
            )}
          >
            Measure
          </div>
          <div
            onClick={() => handleTabClick("analyse")}
            className={classNames(
              styles.th,
              activeHeader === "analyse" && styles.active,
              !isTableExpanded && styles.disabledTab,
            )}
          >
            Analyse
          </div>
          <div
            onClick={() => handleTabClick("improve")}
            className={classNames(
              styles.th,
              activeHeader === "improve" && styles.active,
              !isTableExpanded && styles.disabledTab,
            )}
          >
            Improve
          </div>
        </div>
        {isModuleLoading ? (
          <Loader text="Loading, it will be ready shortly." />
        ) : (
          isTableExpanded && (
            <div className={styles.content}>{renderContent()}</div>
          )
        )}
      </div>
    )
  );
};

export default CustomTable;
