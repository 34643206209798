import React from "react";
import Button from "./Button";
import styles from "./Button.module.scss";
import classNames from "classnames";

const SecondaryButton = ({
  text,
  type,
  onClick,
  disabled,
  width,
  className,
  icon,
  iconPosition,
}) => {
  return (
    <Button
      text={text}
      type={type}
      onClick={onClick}
      disabled={disabled}
      width={width}
      className={classNames(className, styles.secondaryButtonWrapper)}
      icon={icon}
      iconPosition={iconPosition}
    />
  );
};

export default SecondaryButton;
