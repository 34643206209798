import React from "react";
import styles from "./ConfirmationPopup.module.scss";
import Button from "../components/Button/Button";
import WarningIllustration from "../icons/no-data.svg";

const ConfirmationPopup = ({ onConfirmClose, onCancel }) => (
  <div className={styles.overlay}>
    <div className={styles.popup}>
      <img src={WarningIllustration} className={styles.illustration} />
      <p>Your changes won’t be saved if you close this window.</p>
      <div className={styles.buttonGroup}>
        <Button
          text="Cancel"
          onClick={onCancel} // close the confirmation popup
          className={styles.cancelButton}
        />
        <Button
          text="Close Without Saving"
          onClick={onConfirmClose} // Close both the confirmation popup and the audits modal
          className={styles.confirmButton}
        />
      </div>
    </div>
  </div>
);

export default ConfirmationPopup;
