import React, { useEffect, useState } from "react";
import MainLayout from "../MainLayout/MainLayout";
import styles from "./TwoColumnLayout.module.scss";
import { useDispatch, useSelector } from "react-redux";
import Separator from "../../components/Separator/Separator";
import axiosInstance from "../../utils/utils";
import { setConfluencePage } from "../../redux/confluenceStore";
import classNames from "classnames";
import { setLoadingContext } from "../../redux/loaderStore";
import Loader from "../../components/Loader/Loader";
import _ from "lodash";
import pageNotFoundIllustration from "../../icons/404.svg";
import { useLocation } from "react-router-dom";
import { hasRealContent } from "../../utils/helpers";
import { COMPONENT_LOADER, MODAL_LOADER } from "../../constants/loader";
import ConfluencePageDataModal from "../../modals/ConfluencePageDataModal";
import { showModal } from "../../redux/modalStore";
import { setModalConfluencePage } from "../../redux/modalConfluenceStore";
import CustomTable from "../../components/CustomTable/CustomTable";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Button from "../../components/Button/Button";

const TwoColumnLayout = (props) => {
  const orgId = localStorage.getItem("orgId");
  const location = useLocation();

  const [selectedChapter, setSelectedChapter] = useState(null);
  const [selectedPageId, setSelectedPageId] = useState(null);

  const confluenceTreeData = useSelector(
    (state) => state.confluence.confluenceTreeData,
  );
  const confluencePageData = useSelector(
    (state) => state.confluence.confluencePageData,
  );
  const modalConfluencePageData = useSelector(
    (state) => state.modalConfluence.modalConfluencePageData,
  );
  const module = useSelector((state) => state.user.modules[orgId]);
  const loadingContexts = useSelector((state) => state.loader.loadingContexts);
  const notFoundPage = useSelector((state) => state.layout.notFoundPage);
  const currentOrganization = useSelector(
    (state) => state.user.organizations[orgId],
  );

  console.log(confluencePageData, "page data");

  const isComponentLoading = loadingContexts[COMPONENT_LOADER];

  const dispatch = useDispatch();

  const sessionToken = localStorage.getItem("session_token");

  // Extracting path from URL
  const lastIndex = location.pathname.lastIndexOf("/");
  const modifiedPath = location.pathname.substring(lastIndex);

  const extractChapter = (pageName) => {
    const match = pageName.match(/^([A-H]\d*)\s*[-–]\s*/);
    return match ? match[1] : null;
  };

  // Find the module object corresponding to the current path
  const currentModule = module?.find((mod) => mod.path === modifiedPath);

  console.log(currentModule, "currentModule");

  const getConfluencePageData = (pageId, pageName) => {
    const chapter = extractChapter(pageName);

    setSelectedChapter(chapter);
    setSelectedPageId(pageId);

    // Extract the space parameter from the module object
    const space = currentModule?.params?.space || "";

    const options = {
      method: "GET",
      url: `/confluence/page?org_id=${orgId}&space_key=${space}&page_id=${pageId}`,
      headers: {
        "session-token": sessionToken,
      },
    };

    dispatch(setLoadingContext({ context: COMPONENT_LOADER, isLoading: true }));

    return axiosInstance
      .request(options)
      .then((response) => {
        dispatch(
          setLoadingContext({ context: COMPONENT_LOADER, isLoading: false }),
        );
        dispatch(setConfluencePage(response.data));
      })
      .catch((error) => {
        dispatch(
          setLoadingContext({ context: COMPONENT_LOADER, isLoading: false }),
        );
        dispatch(
          showModal({
            name: "error_modal",
          }),
        );
      });
  };

  const getModalConfluencePageData = (space, pageId) => {
    const options = {
      method: "GET",
      url: `/confluence/page?org_id=${orgId}&space_key=${space}&page_id=${pageId}`,
      headers: {
        "session-token": sessionToken,
      },
    };

    dispatch(setLoadingContext({ context: MODAL_LOADER, isLoading: true }));

    return axiosInstance
      .request(options)
      .then((response) => {
        dispatch(
          setLoadingContext({ context: MODAL_LOADER, isLoading: false }),
        );
        dispatch(setModalConfluencePage(response.data));
      })
      .catch(() => {
        dispatch(
          setLoadingContext({ context: MODAL_LOADER, isLoading: false }),
        );
        dispatch(
          showModal({
            name: "error_modal",
          }),
        );
      });
  };

  // Function to generate JSX for the page names
  const renderPageNames = (data, level = 0, currentPath) => {
    const isActive = confluencePageData?.title === data.page_name;

    return (
      <div
        key={data.page_id}
        className={styles.content}
        style={{ marginLeft: level * 20 }}
      >
        <div
          onClick={() =>
            getConfluencePageData(
              data.page_id,
              data.page_name,
              location.pathname,
            )
          }
          className={classNames(styles.pageItems, isActive && styles.active)}
        >
          {data.page_name}
        </div>
        {data?.children?.length > 0 && (
          <div className={styles.items}>
            {data?.children?.map((child) => (
              <div key={child.page_id}>{renderPageNames(child, level + 1)}</div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const isConfluencePageData = !_.isEmpty(confluencePageData);
  // Send request for the first page item when the component mounts and confluenceTreeData is available
  useEffect(() => {
    if (confluenceTreeData?.children?.length > 0) {
      const firstPageId = confluenceTreeData.children[0].page_id;
      getConfluencePageData(firstPageId, modifiedPath);
    }
  }, [confluenceTreeData, modifiedPath]);

  const isContentEmpty = !hasRealContent(confluencePageData?.body);

  const addOnClickHandlersToLinks = (htmlContent) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(htmlContent, "text/html");

    const links = htmlDoc.querySelectorAll("a[c-page_id][c-space]");
    links.forEach((link) => {
      const cSpace = link.getAttribute("c-space");
      const cPageId = link.getAttribute("c-page_id");
      link.setAttribute(
        "onclick",
        `handleLinkClick(event, '${cSpace}', ${cPageId})`,
      );
    });

    return htmlDoc.body.innerHTML;
  };

  // Function to handle link clicks
  const handleLinkClick = (event, cSpace, cPageId) => {
    event.preventDefault();
    getModalConfluencePageData(cSpace, cPageId);
    dispatch(
      showModal({
        name: "quality_management_system_modal",
      }),
    );
  };

  useEffect(() => {
    window.handleLinkClick = handleLinkClick;
  }, []);

  // Handler for opening the "Edit QMS" link in a new tab
  const handleEditQMSClick = () => {
    const space = currentModule?.params?.space || "";
    const pageId = selectedPageId;
    const atlassianDomain = currentOrganization?.AtlassianDomain;

    if (atlassianDomain && space && pageId) {
      const editUrl = `https://${atlassianDomain}/wiki/spaces/${space}/pages/${pageId}`;
      window.open(editUrl, "_blank");
    } else {
      console.error("Could not construct the Atlassian edit URL.");
    }
  };

  console.log(confluenceTreeData, "tree data");

  return (
    <MainLayout>
      <div className={styles.column}>
        <div className={styles.top}>
          <Breadcrumbs data={currentModule} />
          {confluenceTreeData?.page_name === "Quality Management System" && (
            <Button
              text="Edit QMS"
              className={styles.editButton}
              onClick={handleEditQMSClick}
            />
          )}
        </div>
        {notFoundPage ? (
          <div className={styles.pageNotFoundWrapper}>
            <img src={pageNotFoundIllustration} alt="404 Illustration" />
            <span>
              Oops! We can't seem to find the page you're looking for.
            </span>
          </div>
        ) : (
          <>
            <Separator />
            <div
              className={classNames(
                styles.wrapper,
                isConfluencePageData && styles.rightSideActive,
              )}
            >
              <div className={styles.leftSide}>
                <div className={styles.leftSideItems}>
                  <div className={styles.title}>
                    {confluenceTreeData.page_name}
                  </div>
                  {confluenceTreeData?.children?.map((child) =>
                    renderPageNames(child),
                  )}
                </div>
              </div>
              <Separator vertical className={styles.separator} />
              {(isComponentLoading || isConfluencePageData) && (
                <div className={styles.rightSide}>
                  {isComponentLoading && (
                    <Loader text="Loading, thank you for your patience." />
                  )}
                  {isConfluencePageData && !isComponentLoading && (
                    <div className={styles.rightSideWrapper}>
                      <h1>{confluencePageData?.title}</h1>
                      <CustomTable
                        selectedChapter={selectedChapter}
                        className={classNames(
                          isContentEmpty && styles.fullHeight,
                        )}
                      />
                      {!isContentEmpty && (
                        <>
                          <Separator className={styles.dataSeparator} />
                          <div className={classNames(styles.dataWrapper)}>
                            <div
                              id="confluence-content"
                              dangerouslySetInnerHTML={{
                                __html: addOnClickHandlersToLinks(
                                  confluencePageData?.body,
                                ),
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
        <ConfluencePageDataModal
          modalConfluencePageData={modalConfluencePageData}
          addOnClickHandlersToLinks={addOnClickHandlersToLinks}
        />
      </div>
    </MainLayout>
  );
};

export default TwoColumnLayout;
