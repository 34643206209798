import React, { useState } from "react";
import Modal from "../components/Modal/Modal";
import styles from "./ExportModal.module.scss";
import MultiSelectSimple from "../components/MultiSelectSimple/MultiSelectSimple";
import Button from "../components/Button/Button";
import axiosInstance from "../utils/utils";
import { useParams } from "react-router-dom";
import NotificationPopup from "./NotificationPopup";
import { hideModal } from "../redux/modalStore";
import { useDispatch } from "react-redux";

const multiSelectOptions = [
  { id: 1, label: "QMS document", key: "qms" },
  { id: 2, label: "Findings", key: "findings" },
  { id: 3, label: "Improvement Projects", key: "improvements" },
  { id: 4, label: "Audits", key: "audit_tasks" },
  { id: 5, label: "Audit Forms", key: "audits" },
  { id: 6, label: "Permits", key: "permits" },
];

const ExportModal = () => {
  const { orgId } = useParams();
  const sessionToken = localStorage.getItem("session_token");
  const dispatch = useDispatch();

  const [selectedValues, setSelectedValues] = useState([]);
  const [showNotification, setShowNotification] = useState(false);

  const handleExport = async () => {
    const selectedKeys = selectedValues.map(
      (id) => multiSelectOptions.find((option) => option.id === id)?.key,
    );

    const isQmsSelected = selectedKeys.includes("qms");

    const exportRequests = [];

    if (isQmsSelected) {
      exportRequests.push(exportQms(orgId, sessionToken));
    }

    if (selectedKeys.some((key) => key !== "qms")) {
      exportRequests.push(exportExcel(orgId, sessionToken, selectedKeys));
    }

    try {
      await Promise.all(exportRequests);
      setSelectedValues([]);
    } catch (error) {
      console.error("Error during export:", error);
    }
  };

  const exportQms = async (orgId, sessionToken) => {
    try {
      const options = {
        method: "GET",
        url: `export/qms?org_id=${orgId}`,
        headers: { "session-token": sessionToken },
      };

      const response = await axiosInstance.request(options);

      if (response.status === 200) {
        // Show the notification popup
        setShowNotification(true);

        // Automatically hide the popup after 15 seconds
        setTimeout(() => {
          setShowNotification(false);
        }, 15000);
      }
    } catch (error) {
      console.error("Error exporting QMS:", error);
    }
  };

  const exportExcel = async (orgId, sessionToken, selectedKeys) => {
    const params = {
      org_id: orgId,
      findings: selectedKeys.includes("findings"),
      improvements: selectedKeys.includes("improvements"),
      audit_tasks: selectedKeys.includes("audit_tasks"),
      audits: selectedKeys.includes("audits"),
      permits: selectedKeys.includes("permits"),
    };

    try {
      const options = {
        method: "GET",
        url: "export/excel",
        params,
        headers: { "session-token": sessionToken },
        responseType: "blob",
      };

      const response = await axiosInstance.request(options);

      // Generate dynamic filename based on selected options and date
      const selectedLabels = selectedKeys
        .map(
          (key) =>
            multiSelectOptions.find((option) => option.key === key).label,
        )
        .join("_");

      // Generate the date (YYYY-MM-DD)
      const date = new Date().toISOString().slice(0, 10); // get only date

      // Filename with selected labels and date
      const filename = `export_${selectedLabels}_${date}.xlsx`;

      // Handle file download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error exporting Excel:", error);
    }
  };

  const handleModalClose = () => {
    // Reset selected values when modal is closed
    setSelectedValues([]);
    dispatch(hideModal());
  };

  return (
    <Modal
      name="export_modal"
      title="Export"
      width="40vw"
      onClose={handleModalClose}
    >
      <div className={styles.content}>
        <div className={styles.text}>Select the files you wish to export:</div>
        <div className={styles.wrapper}>
          <MultiSelectSimple
            options={multiSelectOptions}
            selectedValues={selectedValues}
            onChangeSelectedValues={setSelectedValues}
          />
        </div>
        <Button
          text="Export"
          className={styles.exportButton}
          onClick={handleExport}
          disabled={selectedValues.length === 0}
        />
      </div>
      {showNotification && (
        <NotificationPopup
          message={
            <>
              <strong>QMS document</strong> export is being created. Once it’s
              finished, it will be sent to your email address. This should take
              no longer than 15 minutes.
            </>
          }
          onClose={() => setShowNotification(false)}
        />
      )}
    </Modal>
  );
};

export default ExportModal;
