import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import styles from "./ModalSideBar.module.scss";
import Button from "../../components/Button/Button";
import classNames from "classnames";
import Textarea from "../../components/Textarea/Textarea";
import axiosInstance from "../../utils/utils";
import { useParams } from "react-router-dom";
import FormikUpload from "../FormikComponents/FormikUpload/FormikUpload";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationData } from "../../actions/organization";
import SortAscendingIcon from "../../icons/sort-ascending.svg";
import SortDescendingIcon from "../../icons/sort-descending.svg";
import { Tooltip } from "react-tooltip";

const ModalSideBar = ({
  isOpen,
  onClose,
  updates,
  setUpdates,
  findingId,
  improvementId,
  auditTaskId,
}) => {
  const { orgId } = useParams();
  const sessionToken = localStorage.getItem("session_token");
  const dispatch = useDispatch();

  const userEmail = useSelector((state) => state.user.email);

  const [sortOrder, setSortOrder] = useState("desc");

  console.log(updates, "updates");

  const handleSubmit = async (
    values,
    { resetForm, setFieldValue, setSubmitting },
  ) => {
    try {
      const requestBody = {
        body: values.body,
        attachments: values.attachments.map((attachment) => attachment),
        finding: findingId || 0,
        improvement: improvementId || 0,
        audit_task: auditTaskId || 0,
      };

      const response = await axiosInstance.post(
        `/nocodb/update?org_id=${orgId}`,
        requestBody,
        {
          headers: {
            "session-token": sessionToken,
            "Content-Type": "application/json",
          },
        },
      );

      if (response.status === 200) {
        // Add the new update to the updates list
        setUpdates((prevUpdates) => [
          ...prevUpdates,
          {
            author: userEmail,
            date: new Date().toISOString(),
            body: values.body,
            attachments: values.attachments,
          },
        ]);

        // Reset the attachments after successful form submission
        setFieldValue("attachments", []);

        // Dispatch getOrganizationData and wait for it to complete
        await dispatch(
          getOrganizationData(
            orgId,
            sessionToken,
            null,
            false,
            Boolean(findingId),
            Boolean(improvementId),
            Boolean(auditTaskId),
            false,
            false,
          ),
        );

        resetForm(); // Reset the form fields
        onClose(); // Close the modal after the dispatch completes
      }
    } catch (error) {
      console.error("Error adding update:", error);
    } finally {
      setSubmitting(false);
    }
  };

  // Sort updates based on the selected order
  const sortedUpdates = [...updates].sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
  });

  // Function to toggle sorting order
  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  return (
    <div className={classNames(styles.sidebar, { [styles.open]: isOpen })}>
      <div className={styles.header}>
        <div className={styles.leftSide}>
          <div className={styles.title}>Updates</div>
          {sortedUpdates.length > 1 && (
            <div
              data-tooltip-id="sort-tooltip"
              data-tooltip-content={`Sort by Date: ${
                sortOrder === "desc" ? "Newest First" : "Oldest First"
              }`}
              className={styles.sortIconContainer}
            >
              <img
                src={
                  sortOrder === "desc" ? SortDescendingIcon : SortAscendingIcon
                }
                className={styles.sortIcon}
                alt="Sort Updates"
                onClick={toggleSortOrder}
              />
            </div>
          )}
        </div>
        <Button
          text="Close"
          onClick={onClose}
          className={styles.button}
          type="button"
        />
        <Tooltip id="sort-tooltip" place="top" className={styles.tooltip} />
      </div>
      <div className={styles.scrollableContent}>
        {/* Updates List */}
        <div className={styles.content}>
          {sortedUpdates.length ? (
            sortedUpdates.map((update, index) => (
              <div key={index} className={styles.updateItem}>
                <div className={styles.updateAuthor}>
                  <strong>Author:</strong> {update.author}
                </div>
                <div className={styles.updateDate}>
                  <strong>Date:</strong>{" "}
                  {new Date(update.date).toLocaleString()}
                </div>
                <div className={styles.updateBody}>
                  <strong>Update:</strong>
                  <div>
                    {update.body.split("\n").map((line, index) =>
                      line.trim().startsWith("-") ? (
                        <ul key={index}>
                          <li>{line.substring(1).trim()}</li>
                        </ul>
                      ) : (
                        <p key={index}>{line}</p>
                      ),
                    )}
                  </div>
                </div>
                {update.attachments && update.attachments.length > 0 && (
                  <div className={styles.updateAttachments}>
                    <strong>Attachments:</strong>
                    <ul>
                      {update.attachments.map((attachment, attachmentIndex) => (
                        <li key={attachmentIndex}>
                          <a
                            href={attachment.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {attachment.filename}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>No updates available.</p>
          )}
        </div>

        {/* Formik Form for adding comments/updates */}
        <Formik
          initialValues={{ body: "", attachments: [] }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className={styles.formContainer}>
              <div>{userEmail}</div>
              <div className={styles.formGroup}>
                <Field
                  name="body"
                  component={Textarea}
                  placeholder="Write your comment here..."
                />
              </div>
              <div className={styles.formGroup}>
                <Field name="attachments" component={FormikUpload} yellowIcon />
              </div>
              <Button
                type="submit"
                text={isSubmitting ? "Submitting..." : "Submit"}
                disabled={isSubmitting}
                className={styles.submitButton}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ModalSideBar;
