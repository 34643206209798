import React from "react";
import styles from "./Card.module.scss";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import SecondaryButton from "../Button/SecondaryButton";
import ArrowIcon from "../../icons/right-arrow.svg";

const Card = ({ className, category, modules }) => {
  const { orgId } = useParams();
  const navigate = useNavigate();

  const getCategoryColorClassName = (category) => {
    switch (category) {
      case "define":
        return styles.borderDefine;
      case "analyse":
        return styles.borderAnalyse;
      case "measure":
        return styles.borderMeasure;
      case "improve":
        return styles.borderImprove;
      case "control":
        return styles.borderControl;
      default:
        return "";
    }
  };

  return (
    <div
      className={classNames(
        styles.card,
        getCategoryColorClassName(category),
        className,
      )}
    >
      <div className={styles.categoryText}>{category}</div>
      <div className={styles.content}>
        {modules.map((module) => (
          <SecondaryButton
            text={module.title}
            onClick={() => navigate(`/${orgId}${module.path}`)}
            className={styles.moduleItem}
            iconPosition="right"
            icon={ArrowIcon}
          />
        ))}
      </div>
    </div>
  );
};

export default Card;
