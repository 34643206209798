import axiosInstance from "../utils/utils";
import { setOrganizationData } from "../redux/organizationStore";
import { showModal } from "../redux/modalStore";
import { setLoadingContext } from "../redux/loaderStore";
import { ORGANIZATION_LOADER } from "../constants/loader";

// Helper function to filter out null or undefined values
const filterNullValues = (data) => {
  return Object.fromEntries(
    Object.entries(data).filter(
      ([_, value]) => value !== null && value !== undefined,
    ),
  );
};

export const getOrganizationData = (
  organizationId,
  sessionToken,
  navigate,
  shouldNavigate = true,
  loadFindings = true,
  loadImprovements = true,
  loadAuditTasks = true,
  loadAudits = true,
  loadPermits = true,
) => {
  return (dispatch) => {
    dispatch(
      setLoadingContext({ context: ORGANIZATION_LOADER, isLoading: true }),
    );

    const options = {
      method: "GET",
      url: `nocodb/organization?org_id=${organizationId}&findings=${loadFindings}&improvements=${loadImprovements}&audit_tasks=${loadAuditTasks}&audits=${loadAudits}&permits=${loadPermits}`,
      headers: { "session-token": sessionToken },
    };

    return axiosInstance
      .request(options)
      .then((response) => {
        // Filter out null or undefined values before dispatching
        const filteredData = filterNullValues(response.data);

        dispatch(setOrganizationData(filteredData));

        dispatch(
          setLoadingContext({ context: ORGANIZATION_LOADER, isLoading: false }),
        );

        if (shouldNavigate) {
          navigate(`/${organizationId}`);
        }
      })
      .catch((error) => {
        console.error("Error fetching organization data:", error);
        dispatch(
          setLoadingContext({ context: ORGANIZATION_LOADER, isLoading: false }),
        );
        dispatch(showModal({ name: "error_modal" }));
      });
  };
};
