import React from "react";
import styles from "./NotificationPopup.module.scss";
import SentEmailIllustration from "../icons/sent-email-illustration.svg";

const NotificationPopup = ({ message, onClose }) => {
  return (
    <div className={styles.notificationOverlay}>
      <div className={styles.notificationPopup}>
        <img
          src={SentEmailIllustration}
          alt="Sent Email Illustration"
          className={styles.illustration}
        />
        <div className={styles.message}>{message}</div>
        <div className={styles.buttonGroup}>
          <button className={styles.closeButton} onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationPopup;
