export const IMPROVEMENT_PROJECTS_MODAL_LOADER =
  "LOADER/IMPROVEMENT_PROJECTS_MODAL_LOADER";
export const CONFLUENCE_TREE_LOADER = "LOADER/CONFLUENCE_TREE_LOADER";
export const COMPONENT_LOADER = "LOADER/COMPONENT_LOADER";
export const MODAL_LOADER = "LOADER/MODAL_LOADER";
export const CUSTOM_MODULE_LOADER = "LOADER/CUSTOM_MODULE_LOADER";
export const PAGE_LOADER = "LOADER/PAGE_LOADER";
export const FINDINGS_MODAL_LOADER = "LOADER/FINDINGS_MODAL_LOADER";
export const AUDITS_LOADER = "LOADER/AUDITS_LOADER";
export const FINDINGS_LOADER = "LOADER/FINDINGS_LOADER";
export const IMPROVEMENTS_PROJECT_LOADER = "LOADER/IMPROVEMENTS_PROJECT_LOADER";
export const ORGANIZATION_LOADER = "LOADER/ORGANIZATION_LOADER";
export const REFRESH_BUTTON_LOADER = "LOADER/REFRESH_BUTTON_LOADER";
