import React, { useState } from "react";
import styles from "./QMSAccordion.module.scss";
import PlusIcon from "../../icons/plus-circle.svg";
import CloseIcon from "../../icons/close-borderly.svg";
import classNames from "classnames";

const QMSAccordion = ({ permit }) => {
  const [isOpen, setIsOpen] = useState(false);

  console.log(permit, "permit in accordion");

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.accordion}>
      <div
        className={classNames(styles.accordionButton, isOpen && styles.opened)}
        onClick={toggleAccordion}
      >
        {permit.name} {/* Permit name at the top */}
        {isOpen ? (
          <img src={CloseIcon} alt="Close icon" className={styles.icon} />
        ) : (
          <img src={PlusIcon} alt="Open icon" className={styles.icon} />
        )}
      </div>
      {isOpen && (
        <div className={styles.accordionContent}>
          <table className={styles.permitTable}>
            <thead>
              <tr>
                <th>Chapter</th>
                <th className={styles.center}>#</th>
                <th>Permit Question</th>
              </tr>
            </thead>
            <tbody>
              {permit.questions.map((question) => (
                <tr key={question.uuid}>
                  <td>{question.chapter}</td>
                  <td>{question.num}</td>
                  <td>{question.question}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default QMSAccordion;
