import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./HomePage.module.scss";
import ListIcon from "../../icons/circle-list.svg";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import WorkInProgressIllustration from "../../icons/work-in-progress-illustration.svg";
import Button from "../../components/Button/Button";
import { setLoadingContext } from "../../redux/loaderStore";
import { PAGE_LOADER } from "../../constants/loader";
import Card from "../../components/Card/Card";
import _ from "lodash";
import Table from "../../components/Table/Table";
import {
  formatDate,
  generateCumulativeRisk,
  generateCumulativeTimeSeries,
  getPast12Months,
} from "../../utils/helpers";
import Select from "../../components/Select/Select";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import DateRangeFilter from "../../components/DateRangeFilter/DateFIlterRange";
import Input from "../../components/Input/Input";
import SearchIcon from "../../icons/search.svg";
import Separator from "../../components/Separator/Separator";
import GraphAccordion from "../../components/GraphAccordion/GraphAccordion";
import classNames from "classnames";
import SecondaryButton from "../../components/Button/SecondaryButton";

const HomePage = () => {
  const { orgId } = useParams();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  localStorage.setItem("orgId", orgId);

  const leftSideRef = useRef(null);
  const rightSideRef = useRef(null);

  const modules = useSelector((state) => state.user.modules);
  const { findingsData, improvementsData, auditsData, data, risksData } =
    useSelector((state) => state.organization);

  console.log(findingsData, "findings data");

  const [organizationModules, setOrganizationModules] = useState(null);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedRiskIds, setSelectedRiskIds] = useState([]);
  const [selectedChapters, setSelectedChapters] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchQuery, setSearchQuery] = useState("");
  const [riskCount, setRiskCount] = useState({});
  const [leftSideHeight, setLeftSideHeight] = useState(0);

  const risksDataArray = risksData ? Object.values(risksData) : [];

  const levelOptions = [
    { id: "low", label: "Low" },
    { id: "medium", label: "Medium" },
    { id: "high", label: "High" },
  ];

  const chapterOptions = _.uniqBy(
    risksDataArray.map((risk) => ({
      id: risk.qms_id,
      label: `${risk.qms_id} - ${risk.qms_description}`,
    })),
    "id", // Ensures uniqueness based on the qms_id
  ).sort((a, b) => a.id.localeCompare(b.id)); // Sorting by qms_id alphabetically

  const riskIdOptions = ["A", "B", "C", "D", "E", "F", "G", "H"].map(
    (letter) => ({
      id: letter,
      label: letter,
    }),
  );

  // Showinf unresolved findings from last month
  const getLastMonthRange = () => {
    const now = new Date();
    const startOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfLastMonth = new Date(startOfCurrentMonth.getTime() - 1);
    const startOfLastMonth = new Date(
      endOfLastMonth.getFullYear(),
      endOfLastMonth.getMonth(),
      1,
    );
    return { startOfLastMonth, endOfLastMonth };
  };

  const { startOfLastMonth, endOfLastMonth } = getLastMonthRange();

  const unresolvedFindings = Object.values(findingsData)
    .filter((finding) => {
      const findingDate = new Date(finding.date);
      return (
        finding.status !== "Done" &&
        (!finding.linked_improvements ||
          finding.linked_improvements.length === 0) &&
        findingDate >= startOfLastMonth &&
        findingDate <= endOfLastMonth
      );
    })
    .sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by date in descending order

  console.log(unresolvedFindings, "unresolved findings");

  // Helper function to recalculate height
  const updateHeight = () => {
    if (leftSideRef.current) {
      const leftHeight = leftSideRef.current.offsetHeight;
      setLeftSideHeight(leftHeight);
    }
  };

  // CODE FOR CALCULATING HEIGHT OF GRAPHS ACCORDION AND FOR LEFT SIDE TO MATCH IT
  // // Recalculate heights when findingsData, improvementsData, or orgId changes
  // useEffect(() => {
  //   updateHeight(); // Immediately after render
  //
  //   const timeoutId = setTimeout(updateHeight, 100); // Ensure a delayed recheck for safety
  //
  //   return () => clearTimeout(timeoutId); // Cleanup the timeout
  // }, [findingsData, improvementsData, orgId]); // Add relevant dependencies
  //
  // // Sync rightSide height after leftSide height has been set
  // useEffect(() => {
  //   if (rightSideRef.current && leftSideHeight) {
  //     const adjustedHeight = leftSideHeight - 25; // Set rightSide to be slightly smaller
  //     rightSideRef.current.style.maxHeight = `${adjustedHeight}px`;
  //   }
  // }, [leftSideHeight]);
  //
  // // Callback function to trigger height recalculation when accordion is toggled
  // const handleAccordionToggle = () => {
  //   setTimeout(() => {
  //     updateHeight(); // Recalculate height when accordion toggles
  //   }, 150); // Delay to ensure accordion is fully expanded/collapsed
  // };

  useEffect(() => {
    dispatch(setLoadingContext({ context: PAGE_LOADER, isLoading: true }));

    if (modules && modules[orgId]) {
      setOrganizationModules(modules[orgId]);
      dispatch(setLoadingContext({ context: PAGE_LOADER, isLoading: false }));
    }
  }, [modules, orgId]);

  const homeModule = organizationModules?.find(
    (module) => module.title === "Home",
  );

  useEffect(() => {
    const riskCount = generateCumulativeRisk(findingsData, improvementsData);
    setRiskCount(riskCount);
  }, [findingsData, improvementsData]);

  const filteredData = risksDataArray.filter((item) => {
    const {
      risk_id,
      description,
      level,
      qms_id,
      qms_description,
      reason,
      date,
    } = item;

    const dueDate = new Date(date);
    const [startDate, endDate] = dateRange;

    const searchQueryLower = searchQuery.toLowerCase();

    const riskIdFirstLetter = risk_id?.charAt(0).toUpperCase();

    return (
      (!selectedRiskIds.length ||
        selectedRiskIds.includes(riskIdFirstLetter)) &&
      (!selectedLevels.length || selectedLevels.includes(level)) &&
      (!selectedChapters.length || selectedChapters.includes(qms_id)) &&
      (!startDate ||
        !endDate ||
        (dueDate >= startDate && dueDate <= endDate)) &&
      (!searchQueryLower ||
        risk_id.toLowerCase().includes(searchQueryLower) ||
        description?.toLowerCase().includes(searchQueryLower) ||
        level?.toLowerCase().includes(searchQueryLower) ||
        reason?.toLowerCase().includes(searchQueryLower) ||
        qms_description?.toLowerCase().includes(searchQueryLower))
    );
  });

  const filterModulesByCategory = (category) => {
    return organizationModules
      ? organizationModules.filter(
          (module) => module.category?.toLowerCase() === category.toLowerCase(),
        )
      : [];
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return (
      <>
        {text.substring(0, maxLength)}
        <span className={styles.readMore}>... read more</span>
      </>
    );
  };

  const getCountForRisk = (risk_num, groupedData) => {
    const riskData = groupedData[risk_num];
    if (!riskData) return "No data"; // If no data exists for the risk number

    const latestMonthData = riskData[riskData.length - 1];
    return latestMonthData ? latestMonthData.count : "No data";
  };

  const getRiskLevelClass = (level) => {
    switch (level?.toLowerCase()) {
      case "low":
        return styles.lowRisk;
      case "medium":
        return styles.mediumRisk;
      case "high":
        return styles.highRisk;
      default:
        return styles.unknownRisk;
    }
  };

  const columns = [
    {
      accessorKey: "risk_id",
      header: "Risk ID",
      size: 120,
      cell: (props) => <div>{props.getValue()}</div>,
      sortingFn: (a, b) => a.original.risk_id.localeCompare(b.original.risk_id), // Custom sorting function for Risk ID
      enableSorting: true, // Enable sorting for Risk ID
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 350,
      cell: (props) => <div>{truncateText(props.getValue(), 80)}</div>,
      enableSorting: false,
    },
    {
      accessorKey: "level",
      header: "Level",
      size: 120,
      cell: (props) => <div>{truncateText(props.getValue(), 80)}</div>,
      enableSorting: false,
    },
    {
      accessorKey: "reason",
      header: "Reason",
      size: 350,
      cell: (props) => <div>{truncateText(props.getValue(), 80)}</div>,
      enableSorting: false,
    },
    {
      accessorKey: "chapter",
      header: "Chapter",
      cell: (props) => {
        const qmsId = props.row.original?.qms_id;
        const qmsDescription = props.row.original?.qms_description;

        return (
          <div>
            {qmsId && qmsDescription ? (
              <div>
                {qmsId} - {qmsDescription}
              </div>
            ) : (
              "No Chapter Info"
            )}
          </div>
        );
      },
      size: 270,
      enableSorting: false,
    },
    {
      accessorKey: "date",
      header: "Date",
      size: 130,
      cell: (props) => (
        <div>
          {props.getValue() ? formatDate(props.getValue()) : "Not defined"}
        </div>
      ),
      sortingFn: "datetime",
    },
    {
      accessorKey: "count",
      header: "RPS",
      size: 100,
      cell: (props) => {
        const risk_num = props.row.original?.risk_num; // Get risk_num from data
        const count = getCountForRisk(risk_num, riskCount); // Get the count using the helper function
        return <div>{count}</div>; // Render the count
      },
      sortingFn: (a, b) => {
        const getParsedCount = (riskNum) => {
          const count = getCountForRisk(riskNum, riskCount);
          return count === "No data" || count === null
            ? 0
            : parseInt(count, 10); // Treat "No data" as 0
        };

        const countA = getParsedCount(a.original?.risk_num);
        const countB = getParsedCount(b.original?.risk_num);

        return countA - countB; // Sort by numeric value
      },
      enableSorting: true,
    },
  ];

  const months = getPast12Months();

  return (
    <MainLayout>
      <div className={styles.homePageWrapper}>
        {homeModule && (
          <>
            <div className={styles.cardWrapper}>
              <Card
                category="define"
                modules={filterModulesByCategory("Define")}
              />
              <Card
                category="measure"
                modules={filterModulesByCategory("Measure")}
              />
              <Card
                category="analyse"
                modules={filterModulesByCategory("Analyse")}
              />
              <Card
                category="improve"
                modules={filterModulesByCategory("Improve")}
              />
              <Card
                category="control"
                modules={filterModulesByCategory("Control")}
              />
            </div>
            <Separator className={styles.separator} />
            <div className={styles.graphsWrapper}>
              <div className={styles.leftSide} ref={leftSideRef}>
                <div className={styles.months}>
                  {months.map((month) => (
                    <div>{month}</div>
                  ))}
                </div>
                <GraphAccordion
                  findingsData={findingsData}
                  improvementsData={improvementsData}
                  // onAccordionToggle={handleAccordionToggle}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className={styles.title}>
                  Unresolved Findings for the Previous Month
                </div>
                <div
                  className={styles.rightSide}
                  ref={rightSideRef}
                  style={{ overflowY: "auto" }}
                >
                  <div className={styles.unresolvedFindings}>
                    {unresolvedFindings.length > 0 ? (
                      unresolvedFindings.map((finding) => (
                        <div key={finding.id} className={styles.findingItem}>
                          <div
                            className={classNames(
                              styles.key,
                              getRiskLevelClass(finding.risk?.level),
                            )}
                          >
                            {finding.key}:
                          </div>
                          <div>
                            {finding.risk
                              ? `${finding.risk?.risk_id} ${finding.risk?.qms_description}`
                              : "Risk is not defined"}
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No unresolved findings.</p>
                    )}
                  </div>
                </div>
                <SecondaryButton
                  text="View all findings"
                  onClick={() => navigate(`/${orgId}/findings`)}
                  className={styles.secondaryButton}
                />
              </div>
            </div>
            <Separator className={styles.separator} />
            <div className={styles.filtersContainer}>
              <div className={styles.filtersWrapper}>
                <MultiSelect
                  label={
                    selectedRiskIds.length > 0
                      ? selectedRiskIds.join(", ")
                      : "Risk ID"
                  }
                  options={riskIdOptions}
                  selectedValues={selectedRiskIds}
                  onChangeSelectedValues={setSelectedRiskIds}
                />
                <MultiSelect
                  label={
                    selectedLevels.length > 0
                      ? selectedLevels.join(", ")
                      : "Level"
                  }
                  options={levelOptions}
                  selectedValues={selectedLevels}
                  onChangeSelectedValues={setSelectedLevels}
                />
                <MultiSelect
                  label={
                    selectedChapters.length > 0
                      ? selectedChapters
                          .map(
                            (id) =>
                              chapterOptions.find((opt) => opt.id === id).label,
                          )
                          .join(", ")
                      : "Chapter"
                  }
                  options={chapterOptions}
                  selectedValues={selectedChapters}
                  onChangeSelectedValues={setSelectedChapters}
                />
                <DateRangeFilter
                  dateRange={dateRange}
                  handleDateRangeChange={setDateRange}
                  isClearable
                />
              </div>
              <Input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                icon={SearchIcon}
                className={styles.input}
              />
            </div>
            <div className={styles.tableContainer}>
              <Table columns={columns} data={filteredData} />
            </div>
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default HomePage;
