import React, { useEffect, useState } from "react";
import Modal from "../components/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  resetModalConfluenceStore,
  setModalConfluencePage,
} from "../redux/modalConfluenceStore";
import { hasRealContent } from "../utils/helpers";
import styles from "./ConfluencePageDataModal.module.scss";
import { MODAL_LOADER } from "../constants/loader";
import Loader from "../components/Loader/Loader";
import _ from "lodash";
import Button from "../components/Button/Button";
import classNames from "classnames";
import { hideModal } from "../redux/modalStore";

const ConfluencePageDataModal = ({
  modalConfluencePageData,
  addOnClickHandlersToLinks,
}) => {
  const loadingContexts = useSelector((state) => state.loader.loadingContexts);
  const [history, setHistory] = useState([]);
  const isModalLoading = loadingContexts[MODAL_LOADER];
  const dispatch = useDispatch();
  const isContentEmpty = !hasRealContent(modalConfluencePageData?.body);

  useEffect(() => {
    if (modalConfluencePageData && !_.isEmpty(modalConfluencePageData)) {
      setHistory((prevHistory) => {
        if (
          _.isEqual(
            prevHistory[prevHistory.length - 1],
            modalConfluencePageData,
          )
        ) {
          return prevHistory; // If the current data is identical to the last entry, do not add it
        }
        return [...prevHistory, modalConfluencePageData];
      });
    }
  }, [modalConfluencePageData]);

  const handlePreviousClick = () => {
    if (history.length > 1) {
      const newHistory = [...history];
      newHistory.pop(); // Remove the current page
      const previousPageData = newHistory[newHistory.length - 1];
      setHistory(newHistory);
      dispatch(setModalConfluencePage(previousPageData));
    }
  };

  return (
    <Modal
      name="quality_management_system_modal"
      title={modalConfluencePageData?.title}
      width="80vw"
      onClose={() => {
        dispatch(resetModalConfluenceStore());
        setHistory([]);
        dispatch(hideModal());
      }}
    >
      {!isModalLoading ? (
        <div className={styles.content}>
          <Button
            onClick={handlePreviousClick}
            disabled={history.length <= 1}
            text="Go back"
            className={classNames(
              styles.button,
              history.length <= 1 && styles.disabled,
            )}
          />
          {isContentEmpty ? (
            <div className={styles.blankPage}>
              <span>Currently, there is no data available.</span>
            </div>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: addOnClickHandlersToLinks(
                  modalConfluencePageData?.body,
                ),
              }}
            />
          )}
        </div>
      ) : (
        <div className={styles.content}>
          <Loader text="Loading, thank you for your patience." />
        </div>
      )}
    </Modal>
  );
};

export default ConfluencePageDataModal;
