import React, { useState } from "react";
import classNames from "classnames";
import styles from "./Accordion.module.scss";
import PlusIcon from "../../icons/plus-circle.svg";
import EditIcon from "../../icons/edit.svg";
import ArrowIcon from "../../icons/arrow.svg";
import QuestionMarkIcon from "../../icons/question-mark.svg";
import { Tooltip } from "react-tooltip";
import { Field } from "formik";
import Upload from "../UploadInput/Upload";
import { getTruncatedContent } from "../../utils/helpers";
import TextareaAutosize from "react-textarea-autosize";

const Accordion = ({
  items,
  openAllByDefault,
  icons,
  tooltip,
  status,
  setFieldValue,
  handleOpenNewFindingModal,
}) => {
  const [openedAccordions, setOpenedAccordions] = useState(
    openAllByDefault ? items.map((_, index) => index) : [],
  );
  const [editMode, setEditMode] = useState({}); // Track which answer is in edit mode

  const toggleAccordion = (index) => {
    setOpenedAccordions(
      (prevAccordions) =>
        prevAccordions.includes(index)
          ? prevAccordions.filter((i) => i !== index) // Close if already open
          : [...prevAccordions, index], // Open if closed
    );
  };

  const toggleEditMode = (index) => {
    setEditMode((prevEditMode) => ({
      ...prevEditMode,
      [index]: !prevEditMode[index], // Toggle edit mode for the given answer
    }));
  };

  return (
    <div>
      {items?.map((item, index) => {
        const isOpen = openedAccordions.includes(index);
        const isEditing = editMode[index]; // Check if the current item is being edited
        const nonNullAnswers = Array.isArray(item.answer.answers)
          ? item.answer.answers.filter((answer) => answer !== null)
          : typeof item.answer.answers === "string"
            ? [item.answer.answers]
            : [];
        const fullAnswer = nonNullAnswers.join(" ");
        const tooltipContent = getTruncatedContent(fullAnswer, 80);

        return (
          <div className={classNames(styles.container)} key={index}>
            <div
              className={styles.questions}
              data-tooltip-id={`tooltip-${index}`}
              data-tooltip-content={tooltipContent}
            >
              <div className={styles.question}>
                <img src={QuestionMarkIcon} alt="Question mark icon" />
                <span>{item.question}</span>
              </div>

              {/* Show edit icon only if there are answers */}
              <div className={styles.iconGroup}>
                {nonNullAnswers.length > 0 && (
                  <div
                    data-tooltip-id={`add-finding-tooltip`}
                    data-tooltip-content="Edit answer"
                  >
                    <img
                      src={EditIcon}
                      alt="Edit icon"
                      className={styles.editIcon}
                      onClick={() => toggleEditMode(index)}
                    />
                  </div>
                )}

                <div
                  data-tooltip-id={`add-finding-tooltip`}
                  data-tooltip-content="Add New Finding"
                  onClick={() => {
                    handleOpenNewFindingModal(item.answer.questionId);
                  }}
                >
                  <img
                    src={PlusIcon}
                    alt="Add new finding"
                    className={styles.newFindingIcon}
                  />
                </div>
              </div>
              <Tooltip
                id={`add-finding-tooltip`}
                place="top"
                className={styles.smallTooltip}
              />
            </div>

            {isOpen && (status !== "Done" || nonNullAnswers.length > 0) && (
              <div className={styles.content}>
                <div className={styles.accordionItems}>
                  {nonNullAnswers.length > 0 && !isEditing && (
                    <div className={styles.answers}>
                      {nonNullAnswers.map((answer, idx) => (
                        <div key={idx} className={styles.answer}>
                          {icons && (
                            <img
                              src={ArrowIcon}
                              alt="Arrow icon"
                              className={styles.arrowIcon}
                            />
                          )}
                          {item.answer.type === "text" ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.answer.modifyDescription(answer),
                              }}
                            />
                          ) : item.answer.type === "file" &&
                            typeof answer === "object" ? (
                            <a
                              href={answer.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {answer.filename}
                            </a>
                          ) : (
                            <span>{answer}</span> // fallback for other cases
                          )}
                        </div>
                      ))}
                    </div>
                  )}

                  {/* Editable mode */}
                  {isEditing && (
                    <>
                      {item.answer.type === "text" ? (
                        <Field name={`responses.${item.answer.questionId}`}>
                          {({ field }) => (
                            <TextareaAutosize
                              {...field}
                              className={styles.textInput}
                              defaultValue={nonNullAnswers.join(" ")} // Use the existing answer for editing
                              style={{
                                width: "100%",
                                padding: "10px",
                              }}
                            />
                          )}
                        </Field>
                      ) : (
                        item.answer.type === "file" && (
                          <Upload
                            questionId={item.answer.questionId}
                            onChange={(filePaths) => {
                              setFieldValue(
                                `responses.${item.answer.questionId}`,
                                filePaths,
                              );
                            }}
                          />
                        )
                      )}
                    </>
                  )}

                  {/* Only show the textarea if there is no answer */}
                  {nonNullAnswers.length === 0 && item.answer.editable && (
                    <>
                      {item.answer.type === "text" ? (
                        <Field name={`responses.${item.answer.questionId}`}>
                          {({ field }) => (
                            <TextareaAutosize
                              {...field}
                              className={styles.textInput}
                              minRows={1}
                              style={{
                                width: "100%",
                                padding: "10px",
                              }}
                            />
                          )}
                        </Field>
                      ) : (
                        item.answer.type === "file" &&
                        item.answer.editable && (
                          <Upload
                            questionId={item.answer.questionId}
                            onChange={(filePaths) => {
                              setFieldValue(
                                `responses.${item.answer.questionId}`,
                                filePaths,
                              );
                            }}
                          />
                        )
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            {!isOpen && tooltip && (
              <Tooltip
                id={`tooltip-${index}`}
                key={`tooltip-${index}`}
                className={styles.largeTooltip}
                place="left-start"
                noArrow
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;
