import React from "react";
import classNames from "classnames";
import styles from "./MultiSelectSimple.module.scss";
import CheckboxIcon from "../../icons/checkbox.svg";

const MultiSelectSimple = ({
  options,
  selectedValues,
  onChangeSelectedValues,
}) => {
  const handleToggleOption = (optionId) => {
    const newSelectedValues = selectedValues.includes(optionId)
      ? selectedValues.filter((id) => id !== optionId)
      : [...selectedValues, optionId];
    onChangeSelectedValues(newSelectedValues);
  };

  return (
    <div className={styles.wrapper}>
      {options.map((option) => (
        <div
          key={option.id}
          className={classNames(styles.optionItem, {
            [styles.selected]: selectedValues.includes(option.id),
          })}
          onClick={() => handleToggleOption(option.id)}
        >
          <div className={styles.checkbox}>
            {selectedValues.includes(option.id) && (
              <img src={CheckboxIcon} alt="checked" />
            )}
          </div>
          <span>{option.label}</span>
        </div>
      ))}
    </div>
  );
};

export default MultiSelectSimple;
