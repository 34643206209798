import React, { useEffect, useRef } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Login from "../views/Login/Login";
import Organizations from "../views/Organizations/Organizations";
import { useDispatch, useSelector } from "react-redux";
import { updateLoggedIn } from "../redux/authStore";
import axiosInstance from "../utils/utils";
import HomePage from "../views/Home/HomePage";
import { setUserData } from "../redux/userStore";
import QualityManagementSystem from "../views/QualityManagementSystem/QualityManagementSystem";
import {
  resetConfluenceStore,
  setConfluenceTree,
} from "../redux/confluenceStore";
import ResourcesArchive from "../views/ResourcesArchive/ResourcesArchive";
import KnowledgeBase from "../views/KnowledgeBase/KnowledgeBase";
import ManagementReports from "../views/ManagementReports/ManagementReports";
import { showNotFoundPage } from "../redux/layoutStore";
import PageLoader from "../components/Loader/PageLoader";
import { setLoadingContext } from "../redux/loaderStore";
import Audits from "../views/Audits/Audits";
import Findings from "../views/Findings/Findings";
import NewFinding from "../views/NewFinding/NewFinding";
import RiskAnalysis from "../views/RiskAnalysis/RiskAnalysis";
import ImprovementProjects from "../views/ImprovementProjects/ImprovementProjects";
import { COMPONENT_LOADER, CONFLUENCE_TREE_LOADER } from "../constants/loader";
import { showModal } from "../redux/modalStore";
import ManageAudits from "../views/ManageAudits/ManageAudits";
import ManageUsersAndOrganizations from "../views/ManageUsers&Organizations/ManageUsersAndOrganizations";

const Router = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const orgId = localStorage.getItem("orgId");
  const hasNavigatedRef = useRef(false);

  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const module = useSelector((state) => state.user.modules[orgId]);

  const getUserData = (sessionToken, refresh = false) => {
    const refreshQuery = refresh ? "&refresh=true" : "";

    const options = {
      method: "GET",
      url: `/auth/session?session_token=${sessionToken}${refreshQuery}`,
    };

    return axiosInstance
      .request(options)
      .then((response) => {
        dispatch(setUserData(response.data));
      })
      .catch(() => {
        dispatch(
          showModal({
            name: "error_modal",
          }),
        );
      });
  };

  // AUTHENTICATION
  useEffect(() => {
    const validateAndFetch = () => {
      dispatch(updateLoggedIn("pending"));

      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      const sessionToken = localStorage.getItem("session_token");

      if (!sessionToken && token) {
        const options = {
          method: "PATCH",
          url: "/auth/session",
          params: { auth_token: token },
        };

        axiosInstance
          .request(options)
          .then((response) => {
            if (response.status === 200) {
              const newSessionToken = response.data.session_token;
              localStorage.setItem("session_token", newSessionToken);
              dispatch(updateLoggedIn(true));
              navigate("/organizations", { replace: true });
            } else {
              console.error("Unexpected status code:", response.status);
              dispatch(updateLoggedIn(false));
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
            } else {
              console.error("Error validating token:", error);
            }
          });
      } else if (sessionToken) {
        dispatch(updateLoggedIn(true));
        getUserData(sessionToken, true)
          .then(() => {
            if (location.pathname === "/login") {
              navigate("/organizations", { replace: true });
            }
          })
          .catch((error) => {
            console.error("Error getting user data:", error);
          });
      } else {
        dispatch(updateLoggedIn(false));
      }
    };

    validateAndFetch();
  }, [dispatch, location.pathname]);

  // CONFLUENCE DOCUMENT TREE
  const getConfluenceTree = (path, sessionToken) => {
    dispatch(
      setLoadingContext({ context: CONFLUENCE_TREE_LOADER, isLoading: true }),
    );
    // Find the matching item in the data array
    const item = module?.find((data) => `/${orgId}${data.path}` === path);

    if (!item || item.module !== "ConfluenceDocumentTree" || !item.params) {
      console.error("Path not found or no parameters available for this path.");
      dispatch(
        setLoadingContext({
          context: CONFLUENCE_TREE_LOADER,
          isLoading: false,
        }),
      );
      return;
    }

    const { space, page_name, page_id } = item?.params;
    let queryParams = `org_id=${orgId}`;

    if (space) {
      queryParams += `&space_key=${space}`;
    }

    if (page_name) {
      queryParams += `&page_name=${encodeURIComponent(page_name)}`;
    } else if (page_id) {
      queryParams += `&page_id=${page_id}`;
    }

    const url = `/confluence/tree?${queryParams}`;

    const options = {
      method: "GET",
      url: url,
      headers: {
        "session-token": sessionToken,
      },
    };

    return axiosInstance
      .request(options)
      .then((response) => {
        dispatch(showNotFoundPage(false));
        dispatch(setConfluenceTree(response.data));
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          dispatch(showNotFoundPage(true));
        } else {
          dispatch(
            showModal({
              name: "error_modal",
            }),
          );
        }
      })
      .finally(() => {
        dispatch(
          setLoadingContext({
            context: CONFLUENCE_TREE_LOADER,
            isLoading: false,
          }),
        );
      });
  };

  const sessionToken = localStorage.getItem("session_token");

  useEffect(() => {
    // Get the current path
    const currentPath = location.pathname;
    getConfluenceTree(currentPath, sessionToken);
  }, [location.pathname]);

  //useRef to prevent loops
  useEffect(() => {
    if (loggedIn === true && orgId && !hasNavigatedRef.current) {
      dispatch(resetConfluenceStore());
      navigate(`/${orgId}`);
      hasNavigatedRef.current = true;
    }
  }, [loggedIn, orgId, dispatch, navigate]);

  return (
    <Routes>
      {loggedIn === true && (
        <Route path="*" element={<Navigate to={"/organizations"} replace />} />
      )}
      {loggedIn === true && (
        <Route index path="/organizations" element={<Organizations />} />
      )}
      {loggedIn === true && <Route path="/:orgId" element={<HomePage />} />}
      {loggedIn === true && (
        <Route path="/:orgId/qms" element={<QualityManagementSystem />} />
      )}
      {loggedIn === true && (
        <Route path="/:orgId/resources" element={<ResourcesArchive />} />
      )}
      {loggedIn === true && (
        <Route path="/:orgId/knowledge-base" element={<KnowledgeBase />} />
      )}
      {loggedIn === true && (
        <Route
          path="/:orgId/management-reports"
          element={<ManagementReports />}
        />
      )}
      {loggedIn === true && (
        <Route path="/:orgId/audits" element={<Audits />} />
      )}
      {loggedIn === true && (
        <Route
          path="/:orgId/improvement-projects"
          element={<ImprovementProjects />}
        />
      )}
      )}
      {loggedIn === true && (
        <Route path="/:orgId/findings" element={<Findings />} />
      )}
      {loggedIn === true && (
        <Route path="/:orgId/new-finding" element={<NewFinding />} />
      )}
      {loggedIn === true && (
        <Route path="/:orgId/risk-analysis" element={<RiskAnalysis />} />
      )}
      {loggedIn === true && (
        <Route path="/:orgId/manage-audits" element={<ManageAudits />} />
      )}
      {loggedIn === true && (
        <Route path="/:orgId/admin" element={<ManageUsersAndOrganizations />} />
      )}
      {loggedIn === false && <Route index path="/login" element={<Login />} />}
      {loggedIn === false && (
        <Route path="*" element={<Navigate to={"/login"} replace />} />
      )}
      {loggedIn === "pending" && (
        <Route
          path="*"
          element={
            <PageLoader text="Just a moment while we prepare your account. This won’t take long." />
          }
        />
      )}
    </Routes>
  );
};

export default Router;
