import { createSlice } from "@reduxjs/toolkit";

export const organizationStore = createSlice({
  name: "organization",
  initialState: {
    data: [],
    risksData: {},
    findingsData: {},
    improvementsData: {},
    auditsData: {},
    permits: {},
    linkedData: {
      linked_improvements: [],
      linked_auditTasks: [],
    },
  },
  reducers: {
    setOrganizationData: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload, // Spread the incoming data to update the state
      };

      // Directly replace risksData if available
      state.risksData = action.payload.risks || state.risksData;

      // Directly replace findingsData
      state.findingsData =
        action.payload.findings !== undefined
          ? action.payload.findings
          : state.findingsData;

      // Directly replace improvementsData
      state.improvementsData =
        action.payload.improvements !== undefined
          ? action.payload.improvements
          : state.improvementsData;

      // Directly replace auditsData
      state.auditsData =
        action.payload.audit_tasks !== undefined
          ? action.payload.audit_tasks
          : state.auditsData;

      // Directly replace permits if available
      state.permits = action.payload.permits || state.permits;
    },
    setLinkedData: (state, action) => {
      state.linkedData = action.payload;
    },
  },
});

export const { setOrganizationData, setLinkedData } = organizationStore.actions;

export default organizationStore.reducer;
