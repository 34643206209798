import React, { useEffect, useState } from "react";
import Modal from "../components/Modal/Modal";
import styles from "./AuditsModal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "../components/Accordion/Accordion";
import { Formik, Form, Field } from "formik";
import axiosInstance from "../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button/Button";
import { hideModal, showModal } from "../redux/modalStore";
import Separator from "../components/Separator/Separator";
import classNames from "classnames";
import EditIcon from "../icons/edit.svg";
import { getOrganizationData } from "../actions/organization";
import Pill from "../components/Pill/Pill";
import Select from "../components/Select/Select";
import Table from "../components/Table/Table";
import SecondaryButton from "../components/Button/SecondaryButton";
import ModalSideBar from "../components/ModalSideBar/ModalSideBar";
import ConfirmationPopup from "./ConfirmationPopup";
import { setLinkedData } from "../redux/findingsStore";
import store from "../core/store";

const AuditsModal = ({ auditData }) => {
  const openedModal = useSelector((state) => state.modal.openedModal);
  const { linkedData, findingsData } = useSelector(
    (state) => state.organization,
  );

  const openedModalData = auditData || openedModal?.data;

  const [loading, setLoading] = useState(false);
  const [editableData, setEditableData] = useState(openedModalData || {});
  const [unsavedChanges, setUnsavedChanges] = useState({});
  const [editField, setEditField] = useState(null);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [updates, setUpdates] = useState(openedModal?.data?.updates || []);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [updatedLinkedData, setUpdatedLinkedData] = useState(null);

  const dispatch = useDispatch();
  const { orgId } = useParams();
  const navigate = useNavigate();
  const sessionToken = localStorage.getItem("session_token");

  // This function extracts linked findings from the Redux store and maps them to the findings data
  const getLinkedData = (audit) => {
    const { linked_findings = [] } = audit;
    const findings = linked_findings
      .map((key) => findingsData?.[key])
      .filter(Boolean);
    return { linked_findings: findings };
  };

  useEffect(() => {
    setEditableData(openedModalData || {});
    setUnsavedChanges({});
    setEditField(null);
    setLoading(false);
    setUpdates(openedModal?.data?.updates || []);

    // Fetch linked data for findings
    if (openedModalData) {
      const updatedData = getLinkedData(openedModalData);
      setUpdatedLinkedData(updatedData);
      dispatch(setLinkedData(updatedData));
    }
  }, [openedModalData, openedModal, dispatch]);

  const renderLinkedFindings = () => {
    const dataToShow = updatedLinkedData?.linked_findings?.length
      ? updatedLinkedData.linked_findings
      : linkedData?.linked_findings || [];

    if (dataToShow.length > 0) {
      return (
        <>
          <div className={styles.title}>Linked Findings</div>
          <div className={styles.table}>
            <Table
              columns={columns}
              data={dataToShow}
              className={styles.table}
            />
          </div>
          <SecondaryButton
            text="View all findings..."
            className={styles.secondaryButton}
            onClick={() => {
              navigate(`/${orgId}/findings`);
              dispatch(hideModal());
            }}
          />
        </>
      );
    } else {
      return (
        <div className={styles.noLinkedFindings}>
          No linked findings available
        </div>
      );
    }
  };

  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  const modifyDescription = (description) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(description, "text/html");
    const links = doc.querySelectorAll("a");

    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    return doc.body.innerHTML;
  };

  const handleSaveResponses = async (values, shouldHideModal = true) => {
    setLoading(true);
    let hasChanges = false;

    // Keep original responses as base or an empty object if none exists
    const finalResponses = { ...editableData.responses };

    const fieldChanges = {};

    // Process form data (responses to questions)
    for (const formItem of openedModalData.form || []) {
      const questions = formItem.questions;

      for (const questionId in questions) {
        const question = questions[questionId];
        const existingAnswer = Array.isArray(question.answer)
          ? question.answer
          : question.answer || ""; // Default to empty string for existingAnswer
        const newResponse = values.responses[questionId];

        if (question.type === "text") {
          // Handle text type questions (where the answer is a string)
          if (typeof newResponse === "string" && newResponse.trim() !== "") {
            // New response is a non-empty string
            finalResponses[questionId] = newResponse;
            hasChanges = true;
          } else if (existingAnswer) {
            // No new response but there is an existing answer
            finalResponses[questionId] = existingAnswer;
          }
        } else if (question.type === "file") {
          // Handle file uploads (where the answer is an array of file paths)
          if (Array.isArray(newResponse)) {
            const combinedFiles = [
              ...(existingAnswer || []).map((answer) => answer.path || answer),
              ...newResponse, // Use the updated file paths directly
            ];

            if (combinedFiles.length > 0) {
              finalResponses[questionId] = combinedFiles;
              hasChanges = true;
            }
          } else if (existingAnswer.length > 0) {
            finalResponses[questionId] = existingAnswer.map(
              (answer) => answer.path || answer,
            );
          }
        } else {
          // Handle array type answers
          if (Array.isArray(newResponse)) {
            const nonNullAnswers = newResponse
              .filter((answer) => answer && answer !== null)
              .map((answer) => {
                return typeof answer === "object" && answer.path
                  ? answer.path
                  : answer;
              });

            if (nonNullAnswers.length > 0 || existingAnswer.length > 0) {
              finalResponses[questionId] = [
                ...existingAnswer.map((answer) => answer.path || answer),
                ...nonNullAnswers,
              ];
              hasChanges = true;
            }
          } else if (existingAnswer.length > 0) {
            // If no new response but there are existing answers, keep them
            finalResponses[questionId] = existingAnswer.map(
              (answer) => answer.path || answer,
            );
          }
        }
      }
    }

    // Always send the final responses even if no new changes
    fieldChanges.responses = finalResponses;

    // Process `responsible` as a string and ensure `accountable`, `consulted`, `informed` are arrays
    const responsibleString = Array.isArray(values.responsible)
      ? values.responsible.join(", ")
      : values.responsible;

    if (editableData.responsible !== responsibleString) {
      fieldChanges.responsible = responsibleString; // Send as a string
      hasChanges = true;
    } else {
      fieldChanges.responsible = responsibleString; // Ensure it's still sent as a string
    }

    ["accountable", "consulted", "informed"].forEach((field) => {
      const newValue = Array.isArray(values[field])
        ? values[field]
        : values[field]?.split(",").map((item) => item.trim()) || [];

      if (JSON.stringify(editableData[field]) !== JSON.stringify(newValue)) {
        fieldChanges[field] = newValue;
        hasChanges = true;
      } else {
        fieldChanges[field] = newValue; // Ensure they're always sent as arrays
      }
    });

    // Handle status separately
    if (editableData.status !== values.status) {
      fieldChanges.status = values.status;
      hasChanges = true;
    }

    // Send payload if changes are detected
    if (hasChanges) {
      try {
        const payload = [
          {
            id: openedModalData.id,
            ...fieldChanges, // All changes including responses
          },
        ];

        await axiosInstance.patch(
          `/nocodb/audit_tasks?org_id=${orgId}`,
          payload,
          {
            headers: {
              "session-token": sessionToken,
              "Content-Type": "application/json",
            },
          },
        );

        await dispatch(
          getOrganizationData(
            orgId,
            sessionToken,
            navigate,
            false,
            false,
            false,
            true,
            false,
            false,
          ),
        );

        setLoading(false);
        // Hide the modal only if the flag `shouldHideModal` is true
        if (shouldHideModal) {
          dispatch(hideModal());
        }
      } catch (error) {
        console.error("Error saving responses:", error);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const initialValues = {
    responses: (openedModalData?.form || []).reduce((acc, formItem) => {
      Object.keys(formItem.questions).forEach((questionId) => {
        acc[questionId] = formItem.questions[questionId].answers || [""];
      });
      return acc;
    }, {}),
    responsible: Array.isArray(editableData.responsible)
      ? editableData.responsible.join(", ")
      : editableData.responsible || "",
    accountable: editableData.accountable || "",
    consulted: editableData.consulted || "",
    informed: editableData.informed || "",
    status: editableData.status || "Open",
  };

  const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) {
      return text;
    }
    return (
      <>
        {text?.substring(0, maxLength)}
        <span className={styles.readMore}>... read more</span>
      </>
    );
  };

  const columns = [
    {
      accessorKey: "key",
      header: "Key",
      size: 100,
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 350,
      cell: (props) => <div>{truncateText(props.getValue(), 80)}</div>,
    },
    {
      accessorKey: "responsible",
      header: "Responsible",
      size: 200,
      cell: (props) => {
        return props.row.original.responsible ? (
          <div>{props.row.original.responsible}</div>
        ) : (
          <div>Not defined</div>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 120,
      cell: (props) => {
        const status = props.getValue();
        let statusClassName = "";

        switch (status) {
          case "Open":
            statusClassName = styles.openStatus;
            break;
          case "In Progress":
            statusClassName = styles.inProgressStatus;
            break;
          case "In Review":
            statusClassName = styles.inReviewStatus;
            break;
          case "Done":
            statusClassName = styles.resolvedStatus;
            break;
          default:
            break;
        }

        return (
          <div className={classNames(styles.statusCell, statusClassName)}>
            {status}
          </div>
        );
      },
    },
  ];

  const handleOnClose = (dirty) => {
    if (dirty) {
      setShowConfirmationPopup(true);
    } else {
      dispatch(hideModal());
      setSidebarOpen(false);
    }
  };

  const handleConfirmClose = () => {
    setShowConfirmationPopup(false);
    dispatch(hideModal()); // Close both the confirmation and the audits modal
    setSidebarOpen(false);
  };

  const handleCancel = () => {
    setShowConfirmationPopup(false); // Just close the confirmation popup
  };

  const handleNavigationClick = (path) => {
    navigate(`/${orgId}/${path}`);
    handleOnClose();
  };

  const statusOptions = [
    { label: "Open", value: "Open" },
    { label: "In Progress", value: "In Progress" },
    { label: "In Review", value: "In Review" },
    { label: "Done", value: "Done" },
  ];

  const renderEmails = (emails, editField, fieldName) => {
    if (editField === fieldName) {
      return (
        <Field
          name={fieldName}
          component={Pill}
          placeholder="Enter emails"
          limit={5}
          parse={(value) => (Array.isArray(value) ? value.join(", ") : value)}
        />
      );
    }

    if (Array.isArray(emails)) {
      return emails.join(", ");
    } else if (typeof emails === "string") {
      return emails;
    }

    return null;
  };

  // const handleOpenNewFindingModal = (questionId) => {
  //   // Find the corresponding form entry for the given questionId
  //   const formEntry = openedModalData.form.find((formItem) => {
  //     return Object.keys(formItem.questions).includes(questionId);
  //   });
  //
  //   if (formEntry) {
  //     const risk = formEntry.risk; // Extract the risk from the matching form entry
  //     dispatch(
  //       showModal({
  //         name: "new_finding_modal",
  //         data: {
  //           ...openedModalData,
  //           questionId,
  //           risk, // Pass the risk to the modal
  //         },
  //       }),
  //     );
  //   } else {
  //     console.error("No form entry found for questionId:", questionId);
  //   }
  // };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSaveResponses(values, true)}
        enableReinitialize
      >
        {({ values, setFieldValue, dirty }) => {
          const handleOpenNewFindingModal = (questionId) => {
            // Call handleSaveResponses before opening the new modal
            handleSaveResponses(values, false);

            // Find the corresponding form entry for the given questionId
            const formEntry = openedModalData.form.find((formItem) => {
              return Object.keys(formItem.questions).includes(questionId);
            });

            if (formEntry) {
              const risk = formEntry.risk; // Extract the risk from the matching form entry
              dispatch(
                showModal({
                  name: "new_finding_modal",
                  data: {
                    ...openedModalData,
                    questionId,
                    risk, // Pass the risk to the modal
                  },
                }),
              );
            } else {
              console.error("No form entry found for questionId:", questionId);
            }
          };
          return (
            <Modal
              name="audits_modal"
              title={openedModalData?.key}
              width="55vw"
              onClose={() => handleOnClose(dirty)}
              isPopupOpen={showConfirmationPopup}
            >
              <div className={styles.content}>
                <Form className={styles.formikContent}>
                  <div
                    className={classNames(styles.firstRow, styles.spaceBetween)}
                  >
                    <div className={classNames(styles.row, styles.start)}>
                      <div className={styles.subheading}>Name:</div>
                      <div className={styles.bold}>{openedModalData?.name}</div>
                    </div>
                    <div className={styles.row}>
                      <Button
                        text={loading ? "Saving..." : "Save Changes"}
                        type="submit"
                        className={styles.submitButton}
                        disabled={loading}
                      />
                      <Button
                        text="See Updates"
                        onClick={toggleSidebar}
                        className={styles.toggleSidebarButton}
                        type="Button"
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.title}>Status:</div>
                    <div className={styles.statusContainer}>
                      {editField === "status" ? (
                        <Field
                          name="status"
                          component={Select}
                          options={statusOptions}
                          value={values.status}
                          onChange={(e) => {
                            setFieldValue("status", e.target.value);
                            setUnsavedChanges((prev) => ({
                              ...prev,
                              status: e.target.value,
                            }));
                            setEditField(null);
                          }}
                        />
                      ) : (
                        <>
                          <div
                            className={classNames(
                              styles.statusCell,
                              values.status === "In Progress"
                                ? styles.inProgressStatus
                                : values.status === "In Review"
                                  ? styles.inReviewStatus
                                  : values.status === "Done"
                                    ? styles.resolvedStatus
                                    : styles.openStatus,
                            )}
                          >
                            {values.status}
                          </div>
                          <img
                            src={EditIcon}
                            className={styles.editIcon}
                            onClick={() => setEditField("status")}
                            alt="Edit"
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {/* Editable Responsible Field */}
                  <div className={styles.row}>
                    <div className={styles.title}>Responsible:</div>
                    <div className={styles.bold}>
                      {renderEmails(
                        values.responsible,
                        editField,
                        "responsible",
                      )}
                    </div>
                    <img
                      src={EditIcon}
                      className={styles.editIcon}
                      onClick={() => setEditField("responsible")}
                      alt="Edit"
                    />
                  </div>

                  {/* Editable Accountable Field */}
                  <div className={styles.row}>
                    <div className={styles.title}>Accountable:</div>
                    <div className={styles.bold}>
                      {renderEmails(
                        values.accountable,
                        editField,
                        "accountable",
                      )}
                    </div>
                    <img
                      src={EditIcon}
                      className={styles.editIcon}
                      onClick={() => setEditField("accountable")}
                      alt="Edit"
                    />
                  </div>

                  {/* Editable Consulted Field */}
                  <div className={styles.row}>
                    <div className={styles.title}>Consulted:</div>
                    <div className={styles.bold}>
                      {renderEmails(values.consulted, editField, "consulted")}
                    </div>
                    <img
                      src={EditIcon}
                      className={styles.editIcon}
                      onClick={() => setEditField("consulted")}
                      alt="Edit"
                    />
                  </div>

                  {/* Editable Informed Field */}
                  <div className={styles.row}>
                    <div className={styles.title}>Informed:</div>
                    <div className={styles.bold}>
                      {renderEmails(values.informed, editField, "informed")}
                    </div>
                    <img
                      src={EditIcon}
                      className={styles.editIcon}
                      onClick={() => setEditField("informed")}
                      alt="Edit"
                    />
                  </div>

                  <Separator />

                  {(editableData.form || []).map((formItem, index) => (
                    <div key={index} className={styles.formItem}>
                      <div className={classNames(styles.row, styles.center)}>
                        <div className={styles.title}>Risk ID:</div>
                        <div className={styles.bold}>
                          {formItem.risk.risk_id}
                        </div>
                      </div>
                      <Accordion
                        items={Object.keys(formItem.questions).map(
                          (questionId) => {
                            const question = formItem.questions[questionId];
                            const currentAnswers = question.answer || [];

                            return {
                              question: question.question,
                              answer: {
                                type: question.type,
                                answers: currentAnswers,
                                questionId: questionId,
                                modifyDescription: modifyDescription,
                                editable: editableData.status !== "Done",
                              },
                            };
                          },
                        )}
                        setFieldValue={setFieldValue}
                        openAllByDefault
                        icons
                        status={editableData?.status}
                        handleOpenNewFindingModal={handleOpenNewFindingModal}
                      />
                    </div>
                  ))}
                </Form>
                {renderLinkedFindings()}
                <ModalSideBar
                  isOpen={isSidebarOpen}
                  onClose={() => setSidebarOpen(false)}
                  updates={updates}
                  setUpdates={setUpdates}
                  auditTaskId={openedModal?.data?.id}
                />
              </div>
            </Modal>
          );
        }}
      </Formik>
      {showConfirmationPopup && (
        <ConfirmationPopup
          onConfirmClose={handleConfirmClose}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

export default AuditsModal;
